import { actions } from './actions';
 
export default function rootReducer(state = {}, changeAction) { 
    if (changeAction.type === 'RESET') {
        const stateData = Object.assign({}, state);
        const obj = {};
        obj.currentUser = stateData.currentUser;
        obj.userAccess = stateData.userAccess;
        obj.appMessage = stateData.appMessage;
        obj.uiModalState = stateData.uiModalState;
        state = obj;
    } else if (changeAction.type === actions.GET_APP_META) {
        const currData = state.meta || {};
        let resData = [];

        if (changeAction.response && changeAction.response.data && changeAction.response.data.data) {
            resData = changeAction.response.data.data;
        }

        currData[changeAction.metaType] = resData;

        if (changeAction.key.toLowerCase() === 'listCountries') {
            currData.listStates = [];
            currData.listCities = [];
        } else if (changeAction.key.toLowerCase() === 'listStates') {
            currData.listCities = [];
        }

        state = Object.assign({}, state, { meta: currData });
    } else if (changeAction.type === actions.APP_MESSAGE) {
        let messageType = 'w3-Khaki';

        if (changeAction.value.type.indexOf('success') > -1) {
            messageType = 'w3-green';
        } else if (changeAction.value.type.indexOf('fail') > -1) {
            messageType = 'w3-red';
        }

        const param = {
            messageType,
            messageText: changeAction.value.text,
        };

        state = Object.assign({}, state, { appMessage: param });
    } else if (changeAction.type === 'SET_UI_MODAL') {
        const param = {};
        param[changeAction.key] = changeAction.value;
        console.log('current ', state.uiModalState);
        const st = Object.assign({}, state.uiModalState, param);
        state = Object.assign({}, state, { uiModalState: st });
    } else if (changeAction.type === actions.SET_DATA) {
        const obj = {};
        obj[changeAction.key] = changeAction.value;

        state = Object.assign({}, state, obj);
    } else if (changeAction.type === actions.CLEAR_PRODUCT_LIST) {
        state = Object.assign({}, state, { productList: null });
    } else if (changeAction.type === actions.GET_APP_REQUEST_ERROR) {
        // console.log('handling error', changeAction);
        const obj = {};
        obj[changeAction.key] = { error: true };
        state = Object.assign({}, state, obj);
    } else if (changeAction.type === actions.GET_APP_REQUEST_SUCCESS) {
        const obj = {};
        if (changeAction.responseKey) {
            obj[changeAction.responseKey] = changeAction.response.data;
        } else {
            obj[changeAction.key] = changeAction.response;
        }
        state = Object.assign({}, state, obj);
    }

    return state;
}

