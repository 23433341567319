import axios from 'axios';


export const actions = {
    GET_APP_REQUEST: 'GET_APP_REQUEST',
    GET_APP_REQUEST_SUCCESS: 'GET_APP_REQUEST_SUCCESS',
    GET_APP_REQUEST_ERROR: 'GET_APP_REQUEST_ERROR',
    SET_DATA: 'SET_DATA',
    GET_APP_META: 'GET_APP_META',
    APP_MESSAGE: 'APP_MESSAGE',
};

export const requestMap = {
    registerUser: {
        path: '/v1/api/auth/register-user',
        method: 'POST',
    },
    editUser: {
        path: '/v1/api/auth/edit-user',
        method: 'POST',
    },
    editProfileLink: {
        path: '/v1/api/auth/edit-profile-link',
        method: 'POST',
    },
    editProfileInfo: {
        path: '/v1/api/auth/edit-profile-info',
        method: 'POST',
    },
    currentUser: {
        path: '/api/v1/user/current-user',
    },
    userNotifications: {
        path: '/api/v1/user/notifications',
    },
    userUnreadNotifications: {
        path: '/api/v1/user/unread-notifications',
    },
    markAsreadNotifications: {
        path: '/api/v1/user/mark-asread-notifications',
        method: 'POST',
    },
    clearNotifications: {
        path: '/api/v1/user/clear-notifications',
        method: 'POST',
    },
    removeNotification: {
        path: '/api/v1/user/remove-notification',
        method: 'POST',
    },
    userDetails: {
        path: '/api/v1/user/user-details',
    },
    jobApplications: {
        path: '/api/v1/job/job-applications',
    },
    allJobApplications: {
        path: '/api/v1/job/all-job-applications',
    },
    searchJobs: {
        path: '/api/v1/position/search-jobs',
        method: 'POST'
    },
    jobTitleList: {
        path: '/api/v1/position/job-title-list',
    },
    jobList: {
        path: '/api/v1/position/current-job-list',
    },
    allJobList: {
        path: '/api/v1/position/current-job-list',
    },
    applicationTitleList: {
        path: '/api/v1/job/application-title-list',
    },
    applicationStatusList: {
        path: '/api/v1/job/application-status-list',
    },
    dashboardCount: {
        path: '/api/v1/job/dashboard-count',
    },
    channelResumesList: {
        path: '/api/v1/job/channel-resumes-list'
    },
    hrList: {
        path: '/api/v1/job/hr-list'
    },
    panelMemberList: {
        path: '/api/v1/job/panel-member-list'
    },
    monthlyHiringStatusList: {
        path: '/api/v1/job/monthly-hiring-status-list'
    },
    profileList: {
        path: '/api/v1/profile/list',
    },
    usersByRoleId: {
        path: '/api/v1/role-user/users-by-role-id',
    },
    userRole: {
        path: '/api/v1/role/manage',
    },
    userRoleList: {
        path: '/api/v1/role-user/list-by-role',
    },
    currentOfficeList: {
        path: '/api/v1/office/current-office-list',
    },
    addCompanyOffice: {
        path: '/api/v1/company/create-company-and-office',
        method: 'POST',
    },
    registerCompanyOffice: {
        path: '/api/v1/company/create-company-and-office-v2',
        method: 'POST',
    },
    resetCompanyOffice: {
        path: '/api/v1/user/reset-current-company-office',
        method: 'POST',
    },
    addCompany: {
        path: '/api/v1/company/create',
        method: 'POST',
    },
    updateCompany: {
        path: '/api/v1/company/edit',
        method: 'POST',
    },
    updateCompanyLogo: {
        path: '/api/v1/company/edit-company-logo',
        method: 'POST',
    },
    deleteCompanyLogo: {
        path: '/api/v1/company/delete-company-logo',
        method: 'POST',
    },
    paypalPayment: {
        path: '/api/v1/payment/pay-with-paypal',
        method: 'POST',
    },
    plansPackagesList: {
        path: '/api/v1/company/plans-packages-list',
    },
    currentPackage: {
        path: '/api/v1/company/current-package',
    },
    companyPackageLimits: {
        path: '/api/v1/company/company-package-limits',
    },
    packagesById: {
        path: '/api/v1/company/packages-by-id',
    },
    updatePackage: {
        path: '/api/v1/company/update-package',
        method: 'POST',
    },
    addOffice: {
        path: '/api/v1/office/add',
        method: 'POST',
    },
    editOffice: {
        path: '/api/v1/office/edit',
        method: 'POST',
    },
    updateSortOffice: {
        path: '/api/v1/office/update-sort-office',
        method: 'POST',
    },
    currentCompany: {
        path: '/api/v1/company/current-company',
    },
    currentWorkflows: {
        path: '/api/v1/workflow/current-workflows',
    },
    selectedWorkflows: {
        path: '/api/v1/workflow/current-selected-workflows',
    },
    userAccess: {
        path: '/api/v1/user/user-access',
    },
    workflowState: {
        path: '/api/v1/workflow-state/list',
    },
    applicationFeedback: {
        path: '/api/v1/job/job-appln-feedback',
    },
    applicationStatus: {
        path: '/api/v1/job/application-status-ex',
    },
    applicationById: {
        path: '/api/v1/job/application-status-by-id',
    },
    updateUserRating: {
        path: '/api/v1/job/update-user-rating',
        method: 'POST',
    },
    applyJob: {
        path: '/api/v1/job/apply',
        method: 'POST',
    },
    addBookmark: {
        path: '/api/v1/job/add-bookmark',
        method: 'POST',
    },
    listInvite: {
        path: '/api/v1/role-user/list-invite',
    },
    resumeDetail: {
        path: '/api/v1/user-resume-block/current-details-list',
    },
    projectList: {
        path: '/api/v1/user-resume-block/project-list',
    },
    currentSkillList: {
        path: '/api/v1/user-resume-block/current-skill-list',
    },
    resumeViewList: {
        path: '/api/v1/user-resume-view/list',
    },
    deleteUserResume: {
        path: '/api/v1/user-resume-view/delete',
        method: 'POST',
    },
    listUserResumeViews: {
        path: '/api/v1/user-resume-view/resume-block-views',
    },
    myApplications: {
        path: '/api/v1/job/my-applications',
    },
    latestThisWeek: {
        path: '/api/v1/job/latest-this-week',
    },
    latestForEmployer: {
        path: '/api/v1/job/latest-for-employer',
    },
    metaData: {
        path: '/api/v1/meta/meta-data',
    },
    listSkills: {
        path: '/api/v1/meta/list-skills',
    },
    listCountries: {
        path: '/api/v1/meta/list-countries',
    },
    listStates: {
        path: '/api/v1/meta/list-states',
    },
    listCities: {
        path: '/api/v1/meta/list-cities',
    },
    recommendedJobs: {
        path: '/api/v1/position/recommended-job-list',
    },
    checkRoleForUser: {
        path: '/api/v1/role-user/check-role-for-user',
    },
    updateCompanyOffice: {
        path: '/api/v1/user/update-company-office',
        method: 'POST',
        responseKey: 'currentUser',
    },
    removeJobPosition: {
        path: '/api/v1/position/delete',
        method: 'POST',
    },
    deleteOffice: {
        path: '/api/v1/office/delete',
        method: 'POST',
    },
    addJobPosition: {
        path: '/api/v1/position/create',
        method: 'POST',
    },
    jobTemplateList: {
        path: '/api/v1/position/job-template-list',
    },

    loginUser: {
        path: '/v1/api/auth/login-user',
        method: 'POST',
    },

    resetPassword: {
        path: '/v1/api/auth-reset/reset-password',
        method: 'POST',
    },

    resetNewPassword: {
        path: '/v1/api/auth-reset/reset-new-password',
        method: 'POST',
    },

    changePassword: {
        path: '/api/v1/user/change-password',
        method: 'POST',
    },

    updateJobPosition: {
        path: '/api/v1/position/edit',
        method: 'POST',
    },
    sendMailToApplicants: {
        path: '/api/v1/position/send-mail-to-applicants',
        method: 'POST',
    },
    assignSelf: {
        path: '/api/v1/process/assign-self',
        method: 'POST',
    },
    assignSchedule: {
        path: '/api/v1/process/assign-schedule',
        method: 'POST',
    },
    notifyCandidate: {
        path: '/api/v1/process/notify-candidate',
        method: 'POST',
    },
    changeCandidateProfileStatus: {
        path: '/api/v1/process/change-candidate-profile-status',
        method: 'POST',
    },
    applicantsProfileStatus: {
        path: '/api/v1/process/applicants-profile-status',
    },
    assignScheduleEdit: {
        path: '/api/v1/process/assign-schedule-edit',
        method: 'POST',
    },
    assignScheduleSelf: {
        path: '/api/v1/process/assign-schedule-self',
        method: 'POST',
    },
    scheduleStateProcess: {
        path: '/api/v1/process/schedule-state-process',
        method: 'POST',
    },
    changeState: {
        path: '/api/v1/process/change-state',
        method: 'POST',
    },
    changeStateEdit: {
        path: '/api/v1/process/change-state-edit',
        method: 'POST',
    },
    scheduleApplicationStatus: {
        path: '/api/v1/process/schedule-application-status',
    },
    profileEdit: {
        path: '/api/v1/profile/edit',
        method: 'POST',
    },
    profileCreate: {
        path: '/api/v1/profile/create',
        method: 'POST',
    },
    profileDelete: {
        path: '/api/v1/profile/delete',
        method: 'POST',
    },
    workflowEdit: {
        path: '/api/v1/workflow/edit',
        method: 'POST',
    },
    workflowCreate: {
        path: '/api/v1/workflow/create',
        method: 'POST',
    },
    workflowDelete: {
        path: '/api/v1/workflow/delete',
        method: 'POST',
    },
    workflowStateEdit: {
        path: '/api/v1/workflow-state/edit',
        method: 'POST',
    },
    workflowStateCreate: {
        path: '/api/v1/workflow-state/create',
        method: 'POST',
    },
    workflowStateCreateTemplate: {
        path: '/api/v1/workflow-state/create-template',
        method: 'POST',
    },
    workflowStateDelete: {
        path: '/api/v1/workflow-state/delete',
        method: 'POST',
    },
    workflowTemplateList: {
        path: '/api/v1/workflow-state/template-list',
    },
    deleteRoleUser: {
        path: '/api/v1/role-user/delete',
        method: 'POST',
    },
    inviteRoleUser: {
        path: '/api/v1/role-user/invite',
        method: 'POST',
    },
    editRoleUser: {
        path: '/api/v1/role-user/edit',
        method: 'POST',
    },
    acceptRoleUserInvite: {
        path: '/api/v1/role-user/accept-invite',
        method: 'POST',
    },
    rejectRoleUserInvite: {
        path: '/api/v1/role-user/reject-invite',
        method: 'POST',
    },
    deleteRole: {
        path: '/api/v1/role/delete',
        method: 'POST',
    },
    createRole: {
        path: '/api/v1/role/create',
        method: 'POST',
    },
    editRole: {
        path: '/api/v1/role/edit',
        method: 'POST',
    },
    userResumeViewEdit: {
        path: '/api/v1/user-resume-view/edit',
        method: 'POST',
    },
    userResumeViewCreate: {
        path: '/api/v1/user-resume-view/create',
        method: 'POST',
    },
    userResumeBlockDelete: {
        path: '/api/v1/user-resume-block/delete',
        method: 'POST',
    },
    userProjectDelete: {
        path: '/api/v1/user-resume-block/project-delete',
        method: 'POST',
    },
    userResumeBlockCreate: {
        path: '/api/v1/user-resume-block/create',
        method: 'POST',
    },
    userResumeBlockEdit: {
        path: '/api/v1/user-resume-block/edit',
        method: 'POST',
    },
    userCertificateCreate: {
        path: '/api/v1/user-resume-block/certificate-create',
        method: 'POST',
    },
    userCertificateEdit: {
        path: '/api/v1/user-resume-block/certificate-edit',
        method: 'POST',
    },
    userProjectCreate: {
        path: '/api/v1/user-resume-block/project-create',
        method: 'POST',
    },
    userProjectEdit: {
        path: '/api/v1/user-resume-block/project-edit',
        method: 'POST',
    },
    changeProfilePic: {
        path: '/api/v1/user-resume-block/change-profile-pic',
        method: 'POST',
    },
    deleteProfilePic: {
        path: '/api/v1/user-resume-block/delete-profile-pic',
        method: 'POST',
    },
    resumeBlockViews: {
        path: '/api/v1/user-resume-view/resume-block-views',
    },
    userResumeViews: {
        path: '/api/v1/user-resume-view/user-resume-views',
    },
    domainJobList: {
        path: '/api/v1/position/domain-job-list',
    },
    domainOfficeList: {
        path: '/api/v1/position/domain-office-list',
    },
    domainJobDetailsById: {
        path: '/api/v1/position/domain-job-details-by-id',
    },
    discoverResume: {
        path: '/api/v1/discover/discover-resumes',
    },
    discoverProfiles: {
        path: '/api/v1/discover/discover-profiles',
        method: 'POST'
    },
    resumeById: {
        path: '/api/v1/discover/resume-by-id',
    },
    addUserSkillsRate: {
        path: '/api/v1/user-resume-block/add-user-skills-rate',
        method: 'POST',
    },
    scheduleWorkflowList: {
        path: '/api/v1/process/schedule-workflow-list',
    },
    scheduleWorkflowCalendarList: {
        path: '/api/v1/process/schedule-workflow-calendar-list',
    },
    jobViews: {
        path: '/api/v1/position/job-views',
        method: 'POST'
    },
    discoverCrawlerProfiles: {
        path: '/v2/es/search',
    },
    activeJobs: {
        path: '/api/v1/position/active-jobs',
    },
    jobById: {
        path: '/api/v1/position/job-by-id',
    },
    applicantOfferStatus: {
        path:'/api/v1/job/applicant-offer-status',
        method: 'POST',
    },
    profileScheduleApplicationStatus: {
        path:'/api/v1/job/profile-schedule-application-status',
    }
};

export const fetchMetaData = (key, metaType, response) => {
    return {
        type: actions.GET_APP_META,
        key,
        metaType,
        response,
        error: null,
    };
};

export const fetchDataRequest = (key, request) => {
    return {
        type: actions.GET_APP_REQUEST,
        key,
        request,
        response: null,
        error: null,
    };
};

export const fetchResponseReceived = (key, request, response) => {
    return {
        type: actions.GET_APP_REQUEST_SUCCESS,
        key,
        request,
        responseKey: requestMap[key].responseKey,
        response,
    };
};

export const fetchDataRequestError = (key, request, error) => {
    return {
        type: actions.GET_APP_REQUEST_ERROR,
        key,
        request,
        response: null,
        error,
    };
};

export const setAppMessage = (type, text) => {
    return {
        type: actions.APP_MESSAGE,
        key: 'appMessage',
        value: {
            type,
            text,
        },
    };
};

export const hideAppMessage = () => {
    return {
        type: actions.APP_MESSAGE,
        key: 'appMessage',
        value: {
            type: '',
            text: '',
        },
    };
};

export const resetState = () => {
    return {
        type: 'RESET',
    };
};

export const fireRequest = (key, path = [], params = {}) => {
    return (dispatch) => {
        const request = Object.assign({}, requestMap[key]);

        if (path.length > 0) {
            request.path += path.join('/');
        }

        if (request.method === undefined) {
            request.method = 'GET';
            request.params = params;
        } else  {
            request.data = params;
        } 
        request.url = request.path;
        request.method = request.method.toLowerCase();
        delete request.path;
        const token = localStorage.getItem('accessToken');
        if (token) {
            request.headers = {
                token
            };
        }

        return axios(request)
            .then((resp) => {
                if (key === 'metaData') {
                    dispatch(fetchMetaData(key, params.type, resp));
                } else if (!resp || resp.statusCode < 300) {
                    dispatch(fetchDataRequestError(key, request, resp.data));
                } else {
                    dispatch(fetchResponseReceived(key, request, resp.data));
                }

                if (!resp.data.success) {
                    dispatch(setAppMessage('fail', resp.data.message));
                }

                return resp;
            })
            .catch((err) => {

                dispatch(setAppMessage('fail', 'Something went wrong...! ' + err.message));
                if (request.url.toLowerCase().indexOf('meta') > -1) {
                    dispatch(fetchMetaData(key, request, []));
                } else {
                    dispatch(fetchDataRequestError(key, request, err));
                }
            });
    };
};

export const modalAction = (key, value) => {
    return {
        type: 'SET_UI_MODAL',
        key,
        value,
    };
};

export const setData = (key, value) => {
    return {
        type: 'SET_DATA',
        key,
        value,
    };
};

export const setHomePageView = (view) => {
    return {
        type: actions.SET_DATA,
        key: 'homePageView',
        value: view,
    };
};

export const getWorkflowTemplateList = () => {
    return fireRequest('workflowTemplateList', [], {});
};

export const getJobTemplateList = () => {
    return fireRequest('jobTemplateList', [], {});
};

export const getMetaData = (data) => {
    return fireRequest('metaData', [], data);
};

export const postRegisterUser = (userData) => {
    return fireRequest('registerUser', [], userData);
};

export const postEditUser = (userData) => {
    return fireRequest('editUser', [], userData);
};

export const postEditProfileLink = (data) => {
    return fireRequest('editProfileLink', [], data);
};

export const postEditProfileInfo = (userData) => {
    return fireRequest('editProfileInfo', [], userData);
};

export const getListSkills = (page = 1) => {
    return fireRequest('listSkills', [], { page });
};

export const getListCountries = (page = 1) => {
    return fireRequest('listCountries', [], { page });
};

export const getListStates = (parentId) => {
    return fireRequest('listStates', [], { parent_id: parentId });
};

export const getListCities = (parentId) => {
    return fireRequest('listCities', [], { parent_id: parentId });
};

export const getCurrentDetailsList = (data) => {
    return fireRequest('resumeDetail', [], data);
};

export const getProjectList = (data) => {
    return fireRequest('projectList', [], data);
};

export const getCurrentSkillList = (page = 1) => {
    return fireRequest('currentSkillList', [], { page });
};

export const getResumeViewList = (page = 1) => {
    return fireRequest('resumeViewList', [], { page });
};

export const deleteUserResume = (resumeId) => {
    return fireRequest('deleteUserResume', [], {
        view_id: resumeId,
    });
};

export const getUserResumeViewList = (listId) => {
    return fireRequest('listUserResumeViews', [], {
        resume_view_id: listId,
    });
};

export const getMyApplications = (page = 1) => {
    return fireRequest('myApplications', [], { page });
};

export const getRecommendedJobList = (filter, page = 1) => {
    return fireRequest('recommendedJobs', [], { filter, page });
};

export const getCurrentUser = (page = 1) => {
    return fireRequest('currentUser', [], { page });
};

export const getUserDetails = (data) => {
    return fireRequest('userDetails', [], data);
};

export const getJobApplications = (data) => {
    const getData = Object.assign(data, {
        office_id: OFFICE_ID
    });
    return fireRequest('jobApplications', [], getData);
};

export const getAllJobApplications = (data) => {
    const getData = Object.assign(data, {
        office_id: OFFICE_ID
    });
    return fireRequest('allJobApplications', [], getData);
};

export const postSearchJobs = (filter, page = 1) => {
    return fireRequest('searchJobs', [], { filter, page });
};

export const getJobTitleList = () => {
    return fireRequest('jobTitleList', [],
        {
            company_id: COMPANY_ID,
            office_id: OFFICE_ID,
        });
};
export const getCurrentJobList = (page = 1, filter = 'all', data = {}) => {
    const getData = Object.assign(data, {
        page,
        company_id: COMPANY_ID,
        office_id: OFFICE_ID,
        filter,
    });
    return fireRequest('jobList', [], getData);
};

export const getAllCurrentJobList = (page = 'all') => {
    return fireRequest('allJobList', [],
        {
            page,
            company_id: COMPANY_ID,
            office_id: OFFICE_ID
        });
};

export const getApplicationTitleList = () => {
    return fireRequest('applicationTitleList', [],
        {
            office_id: OFFICE_ID
        });
};

export const getApplicationStatusList = () => {
    return fireRequest('applicationStatusList', [],
        {
            office_id: OFFICE_ID
        });
};

export const getDashboardCount = () => {
    return fireRequest('dashboardCount', [],
        {
            office_id: OFFICE_ID
        });
};

export const getChannelResumesList = () => {
    return fireRequest('channelResumesList', [],
        {
            office_id: OFFICE_ID
        });
}

export const getHRList = () => {
    return fireRequest('hrList', [],
        {
            office_id: OFFICE_ID
        });
}

export const getPanelMemberList = () => {
    return fireRequest('panelMemberList', [],
        {
            office_id: OFFICE_ID
        });
}

export const getMonthlyHiringStatusList = () => {
    return fireRequest('monthlyHiringStatusList', [],
        {
            office_id: OFFICE_ID
        });
}

export const getCurrentOfficeList = (page = 1) => {
    return fireRequest('currentOfficeList', [],
        {
            page,
            company_id: COMPANY_ID
        });
};

export const postCreateCompanyAndOffice = (companyOfficeData) => {
    return fireRequest('addCompanyOffice', [], companyOfficeData);
};


export const registerCompanyAndOffice = (companyOfficeData) => {
    return fireRequest('registerCompanyOffice', [], companyOfficeData);
};

export const postResetCurrentCompanyOffice = (page = 1) => {
    return fireRequest('resetCompanyOffice', [], page);
};

export const postAddCompany = (companyInfo) => {
    return fireRequest('addCompany', [], companyInfo);
};

export const postUpdateCompany = (companyInfo) => {
    return fireRequest('updateCompany', [], companyInfo);
};

export const postUpdateCompanyLogo = (companyInfo) => {
    return fireRequest('updateCompanyLogo', [], companyInfo);
};

export const postDeleteCompanyLogo = (companyInfo) => {
    return fireRequest('deleteCompanyLogo', [], companyInfo);
};

export const postPaypalPayment = (data) => {
    return fireRequest('paypalPayment', [], data);
};

export const getPlansPackagesList = (page = 1) => {
    return fireRequest('plansPackagesList', [], page);
};

export const getCurrentPackage = (page = 1) => {
    return fireRequest('currentPackage', [],
        {
            page,
            company_id: COMPANY_ID
        });
};

export const getCompanyPackageLimits = (type) => {
    return fireRequest('companyPackageLimits', [],
        {
            type,
            company_id: COMPANY_ID,
            office_id: OFFICE_ID
        });
};

export const getPackagesById = (packageId) => {
    return fireRequest('packagesById', [],
        {
            package_id: packageId,
            company_id: COMPANY_ID
        });
};

export const postUpdatePackage = (packageData) => {
    const data = Object.assign(packageData, {
        company_id: COMPANY_ID
    });
    return fireRequest('updatePackage', [], data);
};

export const getCurrentCompany = (page = 1) => {
    return fireRequest('currentCompany', [],
        {
            page,
            company_id: COMPANY_ID
        });
};

export const postAddOffice = (officeData) => {
    const data = Object.assign(officeData, {
        company_id: COMPANY_ID
    });
    return fireRequest('addOffice', [], data);
};

export const postEditOffice = (officeData) => {
    return fireRequest('editOffice', [], officeData);
};

export const postUpdateSortOffice = (officeData) => {
    return fireRequest('updateSortOffice', [], officeData);
};


export const getCurrentWorkflows = (page = 1) => {
    return fireRequest('currentWorkflows', [],
        {
            page,
            company_id: COMPANY_ID,
            office_id: OFFICE_ID
        });
};

export const getSelectedWorkflows = (data) => {
    return fireRequest('selectedWorkflows', [], data);
};

export const getUsersByRoleId = (data) => {
    return fireRequest('usersByRoleId', [], data);
};

export const getList = (page = 1, status = 'all', search = '', jobId, noticePeriod, step) => {
    return fireRequest('profileList', [],
        {
            page,
            company_id: COMPANY_ID,
            office_id: OFFICE_ID,
           // filter,
            search: search,
            jobId: jobId, 
            notice_period: noticePeriod, 
            step: step,
            status: status,
        });
};

export const getManage = (getAllRoles,page = 1,) => {
    return fireRequest('userRole', [],
        {
            getAllRoles:getAllRoles,
            page,
            company_id: COMPANY_ID,
            office_id: OFFICE_ID,

        });
};

export const getUserAccess = (page = 1) => {
    return fireRequest('userAccess', [], { page });
};

export const getWorkflowState = (workflowId) => {
    return fireRequest('workflowState', [],
        { 
            workflow_id: workflowId,
            company_id: COMPANY_ID,
            office_id: OFFICE_ID
        });
};

export const getListByRole = (roleId) => {
    return fireRequest('userRoleList', [],
        {
            role_id: roleId,
            company_id: COMPANY_ID,
            office_id: OFFICE_ID
        });
};

export const getListInvite = (page = 1) => {
    return fireRequest('listInvite', [], { page });
};

export const getJobApplnFeedback = (applicationId) => {
    return fireRequest('applicationFeedback', [], { application_id: applicationId });
};

export const getApplicationStatus = (applicationId) => {
    return fireRequest('applicationStatus', [], { application_id: applicationId });
};

export const postUpdateUserRating = (data) => {
    return fireRequest('updateUserRating', [], data);
};

export const getApplicationStatusById = (applicationId) => {
    return fireRequest('applicationById', [], { application_id: applicationId });
};

export const postApplyJob = (jobId, resumeViewId, companyId, officeId) => {
    return fireRequest('applyJob', [], {
        job_id: jobId,
        resume_view_id: resumeViewId,
        company_id: companyId,
        office_id: officeId,
    });
};

export const postAddBookmark = (bookmarkIds) => {
    return fireRequest('addBookmark', [], { bookmark_ids: bookmarkIds });
};

export const getCheckRoleForUser = (roleId) => {
    return fireRequest('checkRoleForUser', [], { role_id: roleId });
};

export const postUpdateCompanyOffice = (data) => {
    return fireRequest('updateCompanyOffice', [], data);
};

export const postRemoveJobPosition = (jobId) => {
    return fireRequest('removeJobPosition', [], {
        job_id: jobId,
        office_id: OFFICE_ID
    });
};

export const postDeleteOffice = (officeId) => {
    return fireRequest('deleteOffice', [], {
        office_id: officeId,
        company_id: COMPANY_ID
    });
};

export const postAddJobPosition = (data) => {
    const postData = Object.assign(data, {
        office_id: data && data.office_id ? data.office_id : OFFICE_ID,
        company_id: COMPANY_ID
    });
    return fireRequest('addJobPosition', [], postData);
};

export const postLoginUser = (data) => {
    return fireRequest('loginUser', [], data);
};

export const postResetPassword = (data) => {
    return fireRequest('resetPassword', [], data);
};

export const postResetNewPassword = (data) => {
    return fireRequest('resetNewPassword', [], data);
};

export const postChangePassword = (data) => {
    return fireRequest('changePassword', [], data);
};

export const postUpdateJobPosition = (data) => {
    return fireRequest('updateJobPosition', [], data);
};

export const postAssignSelf = (data) => {
    return fireRequest('assignSelf', [], data);
};

export const postAssignSchedule = (data) => {
    return fireRequest('assignSchedule', [], data);
};

export const postNotifyCandidate = (data) => {
    return fireRequest('notifyCandidate', [], data);
};

export const postChangeCandidateProfileStatus = (data) => {
    return fireRequest('changeCandidateProfileStatus', [], data);
};

export const getApplicantsProfileStatus = (applicant_user_id, applicant_profile_id) => {
    return fireRequest('applicantsProfileStatus', [], { applicant_user_id: applicant_user_id, applicant_profile_id: applicant_profile_id });
};

export const postAssignScheduleEdit = (data) => {
    return fireRequest('assignScheduleEdit', [], data);
};

export const postAssignScheduleSelf = (scheduleId) => {
    return fireRequest('assignScheduleSelf', [], { schedule_id: scheduleId });
};

export const postScheduleStateProcess = (form) => {
    return fireRequest('scheduleStateProcess', [], form);
};

export const postChangeState = (data) => {
    return fireRequest('changeState', [], data);
};

export const postChangeStateEdit = (data) => {
    return fireRequest('changeStateEdit', [], data);
};

export const postProfileEdit = (data) => {
    data.append('company_id', COMPANY_ID);
    data.append('office_id', OFFICE_ID);
    return fireRequest('profileEdit', [], data);
};

export const postProfileCreate = (data) => {
    data.append('company_id', COMPANY_ID);
    data.append('office_id', OFFICE_ID);
    return fireRequest('profileCreate', [], data);
};

export const postProfileDelete = (data) => {
    return fireRequest('profileDelete', [], data);
};

export const postWorkflowEdit = (data) => {
    return fireRequest('workflowEdit', [], data);
};

export const postWorkflowCreate = (data) => {
    const postData = Object.assign(data, {
        company_id: COMPANY_ID,
        office_id: OFFICE_ID
    });
    return fireRequest('workflowCreate', [], postData);
};

export const postWorkflowDelete = (data) => {
    return fireRequest('workflowDelete', [], data);
};

export const postWorkflowStateEdit = (data) => {
    return fireRequest('workflowStateEdit', [], data);
};

export const getProfileScheduleApplicationStatus = (applicant_profile_id) => {
    return fireRequest('profileScheduleApplicationStatus', [], {applicant_profile_id: applicant_profile_id});
};

export const postWorkflowStateCreate = (data) => {
    const postData = Object.assign(data, {
        company_id: COMPANY_ID,
        office_id: OFFICE_ID
    });
    return fireRequest('workflowStateCreate', [], postData);
};

export const postWorkflowStateCreateTemplate = (data) => {
    const postData = Object.assign(data, {
        company_id: COMPANY_ID,
        office_id: OFFICE_ID
    });
    return fireRequest('workflowStateCreateTemplate', [], postData);
};

export const postWorkflowStateDelete = (data) => {
    return fireRequest('workflowStateDelete', [], data);
};

export const postDeleteRoleUser = (roleUserId) => {
    return fireRequest('deleteRoleUser', [], {
        role_user_id: roleUserId,
        company_id: COMPANY_ID,
        office_id: OFFICE_ID
    });
};

export const postInviteRoleUser = (data) => {
    const postData = Object.assign(data, {
        company_id: COMPANY_ID,
        office_id: OFFICE_ID
    });
    return fireRequest('inviteRoleUser', [], postData);
};

export const postEditRoleUser = (data) => {
    return fireRequest('editRoleUser', [], data);
};

export const postAcceptRoleUserInvite = (data) => {
    return fireRequest('acceptRoleUserInvite', [], data);
};

export const postRejectRoleUserInvite = (data) => {
    return fireRequest('rejectRoleUserInvite', [], data);
};

export const postCreateRole = (data) => {
    const postData = Object.assign(data, {
        company_id: COMPANY_ID,
        office_id: OFFICE_ID
    });
    return fireRequest('createRole', [], postData);
};

export const postEditRole = (data) => {
    return fireRequest('editRole', [], data);
};

export const postDeleteRole = (data) => {
    return fireRequest('deleteRole', [], data);
};

export const postUserResumeViewEdit = (data) => {
    return fireRequest('userResumeViewEdit', [], data);
};

export const postUserResumeViewCreate = (data) => {
    return fireRequest('userResumeViewCreate', [], data);
};

export const postUserResumeBlockDelete = (resumeBlockId) => {
    return fireRequest('userResumeBlockDelete', [], { resume_block_id: resumeBlockId });
};

export const postUserProjectDelete = (id) => {
    return fireRequest('userProjectDelete', [], { id: id });
};

export const getResumeBlockViews = (data) => {
    return fireRequest('resumeBlockViews', [], data);
};

export const getUserResumeViews = (resumeViewId) => {
    return fireRequest('userResumeViews', [], { resume_view_id: resumeViewId });
};

export const postUserResumeBlockCreate = (data) => {
    return fireRequest('userResumeBlockCreate', [], data);
};

export const postUserResumeBlockEdit = (data) => {
    return fireRequest('userResumeBlockEdit', [], data);
};

export const postCertificateCreate = (data) => {
    return fireRequest('userCertificateCreate', [], data);
};

export const postCertificateEdit = (form) => {
    return fireRequest('userCertificateEdit', [], { certificateData: JSON.stringify(form) });
};

export const postUserProjectCreate = (data) => {
    return fireRequest('userProjectCreate', [], data);
};

export const postUserProjectEdit = (data) => {
    return fireRequest('userProjectEdit', [], data);
};

export const postChangeProfilePic = (data) => {
    return fireRequest('changeProfilePic', [], data);
};

export const postDeleteProfilePic = (data) => {
    return fireRequest('deleteProfilePic', [], data);
};

export const getDomainJobList = (data) => {
    return fireRequest('domainJobList', [], data);
};

export const getDomainOfficeList = (data) => {
    return fireRequest('domainOfficeList', [], data);
};

export const getDomainJobDetailsById = (jobId) => {
    return fireRequest('domainJobDetailsById', [], { jobId });
};

export const getDiscoverResumes = (search, page) => {
    return fireRequest('discoverResume', [], { search, page });
};

export const getResumeById = (id) => {
    return fireRequest('resumeById', [], { id });
};

export const postSendMailToApplicants = (mailContent) => {
    return fireRequest('sendMailToApplicants', [], mailContent);
};

export const postAddUserSkillsRate = (form) => {
    return fireRequest('addUserSkillsRate', [], { skillsRate: JSON.stringify(form) });
};

export const getScheduleWorkflowList = (appln_id, workflow_id) => {
    return fireRequest('scheduleWorkflowList', [], { appln_id, workflow_id });
};

export const getScheduleWorkflowCalendarList = () => {
    return fireRequest('scheduleWorkflowCalendarList', [], {
        office_id: OFFICE_ID,
        company_id: COMPANY_ID
    });
};

export const getNotifications = () => {
    return fireRequest('userNotifications', [], {});
};

export const getUnreadNotifications = () => {
    return fireRequest('userUnreadNotifications', [], {});
};

export const postMarkAsReadNotifications = () => {
    return fireRequest('markAsreadNotifications', [], {});
};

export const postClearNotifications = () => {
    return fireRequest('clearNotifications', [], {});
};

export const postRemoveNotification = (id) => {
    return fireRequest('removeNotification', [], { id });
};

export const getLatestThisWeek = () => {
    return fireRequest('latestThisWeek', [], {});
};

export const getLatestForEmployer = () => {
    return fireRequest('latestForEmployer', [], {
        company_id: COMPANY_ID,
        office_id: OFFICE_ID,
    });
};

export const getScheduleApplicationStatus = (applicationId) => {
    return fireRequest('scheduleApplicationStatus', [], {
        application_id: applicationId,
        company_id: COMPANY_ID,
        office_id: OFFICE_ID,
    });
};

export const postJobViews = (selectedJob) => {
    return fireRequest('jobViews', [], selectedJob );
};
export const getDiscoverCrawlerProfiles = (data) => {
    return fireRequest('discoverCrawlerProfiles', [], data);
}

export const postDiscoverProfiles = data => {
    return fireRequest('discoverProfiles', [], data);
}

export const getActiveJobs = (page = 1, data = {}) => {
    const getData = Object.assign(data, {
        page,
        company_id: COMPANY_ID,
        office_id: OFFICE_ID,
    });
    return fireRequest('activeJobs', [], getData);
};

export const getJobById = (jobId,companyId,officeId) => {
    return fireRequest('jobById', [], { 
        job_id: jobId ,
        company_id: companyId,
        office_id: officeId
    });
}

export const postApplicantOfferStatus = (data) => {
    return fireRequest('applicantOfferStatus', [], data);
};
