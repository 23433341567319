import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import OpenPositions from '../components/Widget/OpenPositions';
import OpenPositions from '../components/Widget/JobsWidget';
import sdkStore from './sdkClientStore';

require('../styles/widget.scss');
// require('../styles/sdk.scss');

(function () {
    class SDKWrapper {
        domainJobList(config) {
            ReactDOM.render(
                <Provider store={sdkStore}>
                    <BrowserRouter>
                        <OpenPositions domainId={config.domainId} />
                    </BrowserRouter>
                </Provider>,
                document.getElementById(config.container),
            );
        }
    }
    window.clientRoute = function (argument) {
        return argument;
    };
    window.HiringSDK = SDKWrapper;
}());
