import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideAppMessage } from '../../js/actions';

class StickyInfoBar extends Component {
    static get defaultProps() {
        return {
            msgType: 'w3-red',
            msgText: 'Error Occured',
        };
    }

    static propTypes = {
        msgType: PropTypes.string,
        msgText: PropTypes.string,
        hideAppMessage: PropTypes.func,
    };

    handleCloseBar() {
        this.props.hideAppMessage();
    }

    render() {
        setTimeout(() => {
            this.props.hideAppMessage();
        }, 3000);
        const { msgType, msgText } = this.props;
        return (
            <div className={`h7t-sticky h7t-showStickyInfo ${msgType}`}>
                <span
                    onClick={this.handleCloseBar.bind(this)}
                    className={`w3-button w3-margin-right w3-hover-dark-grey w3-padding-small w3-right ${msgType}`}
                >&times;</span>
                <p>{msgText}</p>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, {
    hideAppMessage,
})(StickyInfoBar);
