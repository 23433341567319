import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { modalAction } from '../../js/actions';

class Modal extends Component {
    static propTypes = {
        title: PropTypes.string,
        titleCls: PropTypes.string,
        onClose: PropTypes.func,
        modalCls: PropTypes.string,
        modalKey: PropTypes.string,
        widthCls: PropTypes.string,
        className: PropTypes.string,
        modalAction: PropTypes.func,
        uiModalState: PropTypes.object,
    };

    static get defaultProps() {
        return {
            widthCls: '',
            className: '',
            title: '',
            titleCls: '',
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            modalCls: props.modalCls ? props.modalCls : 'w3-hide',
        };

        this.handelModalClose = this.handelModalClose.bind(this);
        this.hideDatePicker = this.hideDatePicker.bind(this);
    }

    componentDidMount() {
        $('body').addClass('h7t-modal-open');
        $('.h7t-sidebar-section').css('overflow', 'hidden');
    }

    componentWillUnmount() {
        $('body').removeClass('h7t-modal-open');
        $('.h7t-sidebar-section').css('overflow', 'auto');
    }

    handelModalClose() {
        if (this.props.checkChanges) {
            this.props.onClose && this.props.onClose();
            return;
        }

        this.setState({
            modalCls: 'w3-hide',
        });

        this.props.onClose && this.props.onClose();

        if (this.props.uiModalState[this.props.modalKey]) {
            this.props.modalAction(this.props.modalKey, { cls: 'w3-hide' });
        }
    }

    hideDatePicker() {
        this.props.handleOpen(!this.props.datePickerStatus);
    }

    render() {
        const {
            widthCls,
            className,
            title,
            uiModalState,
            modalKey,
            titleCls,
            datePickerStatus,
            id,
            disableClose,
        } = this.props;

        const modalIds = [ "share_job_modal", "resume_view_block", "share_login_modal"];
        const result = modalIds.find((modalId) => modalId == id);
        
        return (
            <div
                className={`w3-modal ${uiModalState[modalKey] ? uiModalState[modalKey].cls : this.state.modalCls}`} 
                onScroll={datePickerStatus ? this.hideDatePicker : null}
            >
                <div className={`w3-modal-content w3-padding w3-animate-top ${widthCls} ${className} `}>
                    <button
                        type="button"
                        onClick={this.handelModalClose}
                        id={id}
                        className={`w3-button w3-right w3-black close-button w3-display-topright ${disableClose && result ? 'w3-disabled':''}` }
                        disabled={disableClose && result}
                    >
                        &times;
                    </button>
                    {title
                        ? (
                            <div className={`${titleCls}`}>
                                <p className="w3-margin h7t-modal-title">{title}</p>
                            </div>
                        ) : null}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        uiModalState: state.uiModalState,
    };
}

export default connect(mapStateToProps, {
    modalAction,
})(Modal);
