// const host = 'http://stage.hiringnxt.com';
let host = 'http://localhost:2468';

const env = process.env.NODE_ENV;
console.log('Widget Domain Env :: ', env);

if (env === 'production' || env === 'prod') {
    host = 'https://hiringnxt.com';
    // host = 'https://hiringnxt.d.ceegees.in';
}

const BaseAjaxDomain = {
    host,
};

export default BaseAjaxDomain;
