import React, { Component } from 'react';
import {
    TextField, MenuItem, Radio, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export class InputTextField extends Component {
    static get defaultProps() {
        return {
            type: 'text',
            required: false,
            multiline: false,
            variant: 'outlined',
            customClass: '',
            wrapperClass: '',
        };
    }

    constructor(args) {
        super(args);
        this.state = {};
    }

    render() {
        const {
            variant, customClass, wrapperClass, label, size, value, name, onChange, required, id, 
            type, errors, placeholder, helperText, multiline, rows, autoFocus, disabled,
        } = this.props;

        return (
            <div className={`w3-panel ${wrapperClass}`}>
                <TextField
                    className={customClass}
                    autoFocus={autoFocus}
                    multiline={multiline}
                    rows={rows}
                    error={errors}
                    type={type}
                    label={label}
                    variant={variant}
                    size={size || 'small'}
                    value={value}
                    name={name}
                    id={id}
                    fullWidth
                    required={required}
                    onChange={onChange}
                    helperText={errors || helperText}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </div>
        );
    }
}

export class InputSelectField extends Component {
    static get defaultProps() {
        return {
            required: false,
            options: [],
            multiple: false,
            variant: 'outlined',
            customClass: '',
            wrapperClass: '',
        };
    }

    constructor(args) {
        super(args);
        this.state = {};
    }

    render() {
        const {
            variant, customClass, wrapperClass, label, id, name, value, onChange, options, size, errors, required, helperText, disabled,
        } = this.props;

        return (
            <div className={`w3-panel ${wrapperClass}`}>
                <TextField
                    select
                    className={customClass}
                    error={errors}
                    label={label}
                    variant={variant}
                    size={size || 'small'}
                    value={value || value == 0 ? value.toString() : ''}
                    name={name}
                    id={id}
                    fullWidth
                    required={required}
                    onChange={onChange}
                    helperText={errors || helperText}
                    disabled={disabled}
                >
                    {options.map((list) => {
                        return (
                            <MenuItem value={list.val}>{list.name}</MenuItem>
                        );
                    })}
                </TextField>
            </div>
        );
    }
}

export class RadioButtonMUI extends Component {
    static get defaultProps() {
        return {
            checked: false,
            labelPlacement: 'end',
            color: 'primary',
            customClass: '',
        };
    }

    constructor(args) {
        super(args);
        this.state = {};
    }

    render() {
        const {
            checked, customClass, onChange, value, name, id, labelPlacement, label, color, size, disabled,
        } = this.props;

        const radioBtn = (
            <Radio
                checked={checked}
                value={value}
                name={name}
                id={id}
                color={color}
                onChange={onChange}
                size={size || 'small'}
                className={customClass}
            />
        );

        return (
            <FormControlLabel
                control={radioBtn}
                label={label}
                labelPlacement={labelPlacement}
                disabled={disabled}
            />
        );
    }
}

export class CheckboxMUI extends Component {
    static get defaultProps() {
        return {
            checked: false,
            labelPlacement: 'end',
            color: 'primary',
            customClass: '',
        };
    }

    constructor(args) {
        super(args);
        this.state = {};
    }

    render() {
        const {
            checked, customClass, onChange, value, name, id, labelPlacement, label, color, size, disabled
        } = this.props;

        const checkBox = (
            <Checkbox
                checked={checked}
                value={value}
                name={name}
                id={id}
                color={color}
                onChange={onChange}
                size={size || 'small'}
                className={customClass}
            />
        );

        return (
            <FormControlLabel
                control={checkBox}
                label={label}
                labelPlacement={labelPlacement}
                disabled={disabled}
            />
        );
    }
}

export class SearchDropdown extends Component {
    static get defaultProps() {
        return {
            required: false,
            label: 'Search',
            options: [],
            multiple: false,
            variant: 'outlined',
            customClass: '',
            wrapperClass: '',
        };
    }

    constructor(args) {
        super(args);
        this.state = {};
    }

    render() {
        const {
            multiple, customClass, wrapperClass, id, value, onChange, options, onSearch, searchKey, variant, label, placeholder, size, required, errors, helperText, disableActive, clearText, disabled
        } = this.props;

        let selectedOption = null;
        if (multiple) {
            selectedOption = [];
        }
        if (value) {
            options.forEach((option) => {
                if (multiple && Array.isArray(value)) {
                    if (value.indexOf(option.val) !== -1) {
                        selectedOption.push(option);
                    }
                } else if (option.val === value) {
                    selectedOption = option;
                }
            });
        }
        return (
            <div className={`w3-panel ${wrapperClass}`}>
                <Autocomplete
                    id={id}
                    clearText={clearText}
                    multiple={multiple}
                    options={options}
                    getOptionLabel={option => option.name}
                    getOptionDisabled={options => {
                        if(disableActive) {
                            if(options.notified != null) {
                                return true;
                            }
                            return false;
                        }
                    }}
                    onChange={onChange}
                    value={selectedOption}
                    onInputChange={onSearch}
                    inputValue={searchKey}
                    autoHighlight
                    size={size || 'small'}
                    className={customClass}
                    disabled={disabled}
                    renderInput={params => (
                    <TextField
                        {...params}
                        variant={variant}
                        label={label}
                        placeholder={placeholder}
                        fullWidth
                        required={required}
                        error={errors}
                        helperText={errors || helperText}
                    />
                    )}
                />
            </div>
        );
    }
}

// export class NextFieldOutlined extends Component {
//     static get defaultProps() {
//         return {
//             required: false,
//         };
//     }

//     constructor(args) {
//         super(args);
//         this.state = {};
//     }

//     render() {
//         return (
//             <div className="w3-panel">      
//             </div>
//         );
//     }
// }

export class CheckboxesTags extends Component{
    static get defaultProps() {
        return {
            required: false,
            label: 'Search',
            options: [],
            multiple: false,
            variant: 'outlined',
            customClass: '',
            wrapperClass: '',
        };
    }

    constructor(args) {
        super(args);
        this.state = {};
    }

    render() {
        const {
            multiple, value1,customClass, wrapperClass, id, value, options,onChange, onSearch, label, required, placeholder, searchKey, size
        } = this.props;
        let chooseOption = null;
        if(multiple) {
            chooseOption = [];
            const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
            const checkedIcon = <CheckBoxIcon fontSize="small" />;

            if (value) {
                options.forEach((option) => {
                    if (multiple && Array.isArray(value)) {
                        if (value.indexOf(option.val) !== -1) {
                            chooseOption.push(option);
                        }
                    } else if (option.val === value) {
                        chooseOption = option;
                    }
                });
            }

  return (
    <div className={`w3-panel ${wrapperClass}`}>
    <Autocomplete
      multiple={multiple}
      id={id}
      options={options}
      disableCloseOnSelect
      className={customClass}
      getOptionLabel={option => option.name}
      onChange={(data1, data2) => {
          onChange(data2);
        }}
      //onInputChange={onSearch}
      //inputValue={searchKey}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            size={size || 'small'}
          />
          {option.name}
        </React.Fragment>
      )}
      size={size || 'small'}
      renderInput={params => (
        <TextField 
        {...params} 
        variant="outlined" 
        label={label}
        placeholder={placeholder} 
        size={size || 'small'}
    />
      )}
    />
    </div>

  );
}
}
}