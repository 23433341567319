import thunkMiddleWare from 'redux-thunk';

import { createLogger } from 'redux-logger';

import { createStore, applyMiddleware, combineReducers } from 'redux';

import rootReducer from './Reducerlists';

const loggerMiddleware = createLogger({ collapsed: true });

const middlewares = [thunkMiddleWare];

__DEV__ && middlewares.push(loggerMiddleware);

const initialState = {
    homePageView: 'login',
    currentUser: null,
    appMessage: {
        messageType: '',
        messageText: '',
    },
    uiModalState: {

    },
};

const sdkStore = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares),
);
window.gHiringSdkStore = sdkStore;
export default sdkStore;
