import axios from 'axios';
import BaseAjaxDomain from './BaseAjaxDomain';

export const actions = {
    GET_APP_REQUEST: 'GET_APP_REQUEST',
    GET_APP_REQUEST_SUCCESS: 'GET_APP_REQUEST_SUCCESS',
    GET_APP_REQUEST_ERROR: 'GET_APP_REQUEST_ERROR',
    SET_DATA: 'SET_DATA',
    GET_APP_META: 'GET_APP_META',
    APP_MESSAGE: 'APP_MESSAGE',
};

export const requestMap = {
    currentUser: {
        path: '/api/v1/user/current-user',
    },
    domainJobList: {
        path: '/api/v1/position/domain-job-list',
    },
    domainOfficeList: {
        path: '/api/v1/position/domain-office-list',
    },
    domainJobDetailsById: {
        path: '/api/v1/position/domain-job-details-by-id',
    },
    metaData: {
        path: '/api/v1/meta/meta-data',
    },
    resumeViewList: {
        path: '/api/v1/user-resume-view/list',
    },
    userResumeViewCreate: {
        path: '/api/v1/user-resume-view/create',
        method: 'POST',
    },
    addBookmark: {
        path: '/api/v1/job/add-bookmark',
        method: 'POST',
    },
    applyJob: {
        path: '/api/v1/job/apply',
        method: 'POST',
    },
    loginUser: {
        path: '/v1/api/auth/login-user',
        method: 'POST',
    },
    registerUser: {
        path: '/v1/api/auth/register-user',
        method: 'POST',
    },
    resetPassword: {
        path: '/v1/api/auth-reset/reset-password',
        method: 'POST',
    },
    registerCompanyOffice: {
        path: '/api/v1/company/create-company-and-office-v2',
        method: 'POST',
    },
};

export const setAppMessage = (type, text) => {
    return {
        type: actions.APP_MESSAGE,
        key: 'appMessage',
        value: {
            type,
            text,
        },
    };
};

export const fetchDataRequest = (key, request) => {
    return {
        type: actions.GET_APP_REQUEST,
        key,
        request,
        response: null,
        error: null,
    };
};

export const fetchMetaData = (key, metaType, response) => {
    return {
        type: actions.GET_APP_META,
        key,
        metaType,
        response,
        error: null,
    };
};

export const fetchDataRequestError = (key, request, error) => {
    return {
        type: actions.GET_APP_REQUEST_ERROR,
        key,
        request,
        response: null,
        error,
    };
};

export const fetchResponseReceived = (key, request, response) => {
    return {
        type: actions.GET_APP_REQUEST_SUCCESS,
        key,
        request,
        responseKey: requestMap[key].responseKey,
        response,
    };
};
axios.defaults.withCredentials = true;
export const fireRequest = (key, path = [], params = {}) => {
    params.domain_id = DOMAIN_ID;
    return (dispatch) => {
        const request = Object.assign({}, requestMap[key]);
        // request.path = 'http://stage.hiringnxt.com' + request.path;
        request.path = `${BaseAjaxDomain.host}${request.path}`;

        if (path.length > 0) {
            request.path += path.join('/');
        }

        if (request.method === undefined) {
            request.method = 'GET';
            request.params = params;
        } else {
            request.data = params;
        }

        request.url = request.path;
        request.method = request.method.toLowerCase();
        delete request.path;

        const token = localStorage.getItem('accessToken');
        if (token) {
            request.headers = {
                token
            };
        }
        
        dispatch(fetchDataRequest(key, request));

        return axios(request)
            .then((resp) => {
                if (key === 'metaData') {
                    dispatch(fetchMetaData(key, params.type, resp));
                } else if (!resp || resp.statusCode < 300) {
                    dispatch(fetchDataRequestError(key, request, resp.data));
                } else {
                    dispatch(fetchResponseReceived(key, request, resp.data));
                }

                if (!resp.data.success) {
                    dispatch(setAppMessage('fail', resp.data.message));
                }

                return resp;
            })
            .catch((err) => {
                dispatch(setAppMessage('fail', 'Something went wrong...! ' + err.message));

                if (request.path.toLowerCase().indexOf('meta') > -1) {
                    dispatch(fetchMetaData(key, request, []));
                } else {
                    dispatch(fetchDataRequestError(key, request, err));
                }
            });
    };
};

export const getCurrentUser = (page = 1) => {
    return fireRequest('currentUser', [], { page });
};

export const getDomainJobList = (data) => {
    return fireRequest('domainJobList', [], data);
};

export const getDomainOfficeList = (data) => {
    return fireRequest('domainOfficeList', [], data);
};

export const getDomainJobDetailsById = (jobId) => {
    return fireRequest('domainJobDetailsById', [], { jobId });
};

export const getMetaData = (data) => {
    return fireRequest('metaData', [], data);
};

export const getResumeViewList = (page = 1) => {
    return fireRequest('resumeViewList', [], { page });
};

export const postUserResumeViewCreate = (data) => {
    return fireRequest('userResumeViewCreate', [], data);
};

export const postAddBookmark = (bookmark_ids) => {
    return fireRequest('addBookmark', [], { bookmark_ids });
};

export const postApplyJob = (jobId, resumeViewId, companyId, officeId) => {
    return fireRequest('applyJob', [], {
        job_id: jobId,
        resume_view_id: resumeViewId,
        company_id: companyId,
        office_id: officeId,
    });
};

export const postLoginUser = (data) => {
    return fireRequest('loginUser', [], data);
};

export const postRegisterUser = (userData) => {
    return fireRequest('registerUser', [], userData);
};

export const postResetPassword = (data) => {
    return fireRequest('resetPassword', [], data);
};

export const registerCompanyAndOffice = (companyOfficeData) => {
    return fireRequest('registerCompanyOffice', [], companyOfficeData);
};

