import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import {
    getMetaData,
    postLoginUser,
    setAppMessage,
    postRegisterUser,
    postResetPassword,
    registerCompanyAndOffice,
} from '../../js/sdkActions';
import BaseAjaxDomain from '../../js/BaseAjaxDomain';
import Modal from '../Common/Modal';
import { FormTextField, ErrorHelperText } from '../Common/HelperComponents';
import { InputSelectField, SearchDropdown } from '../Common/MaterialUI/HelperInputs';
import moment from 'moment';


class WidgetLoginSignupModal extends Component {
    constructor(args) {
        super(args);
        this.state = {
            errorMessage: {},
            formData: {},
            isDisabled: false,
            homePageView: '',
            activeClass: '',
            dpOpenStatus: false,
        };
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleViewChange = this.handleViewChange.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.registerEmployer = this.registerEmployer.bind(this);
        this.handleDatePicker = this.handleDatePicker.bind(this);
        this.handleDPOpenStateChange = this.handleDPOpenStateChange.bind(this);
        this.handleChangeSelection = this.handleChangeSelection.bind(this);
    }

    componentDidMount() {
        const { homePageView } = this.props;
        if (homePageView) {
            this.handleViewChange(homePageView);
        }
        this.props.getMetaData({ type: 'category' });
    }

    updateErrors(errorMessage) {
        if (errorMessage.error) {
            this.props.setAppMessage('fail', errorMessage.error);
        }
        this.setState({
            errorMessage,
        });
    }

    handleViewChange(homePageView) {
        const { errorMessage } = this.state;
        if (Object.keys(errorMessage).length > 0) {
            this.updateErrors({});
        }
        this.setState({ activeClass: homePageView });
        this.handleHomePageView(homePageView);
    }

    handleHomePageView(homePageView) {
        let { formData } = this.state;
        if (homePageView === 'login') {
            formData = {
                email: '',
                password: '',
            };
        } else if (homePageView === 'signup') {
            formData = {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                gender: '',
                dob: '',
                password: '',
                confirm_password: '',
            };
        } else if (homePageView === 'forgot_password') {
            formData = {
                email: '',
            };
        } else if (homePageView === 'employer') {
            formData = {
                first_name: '',
                name: '',
                email: '',
                phone_number: '',
                size: '',
                category: '',
                user_type: 'COMPANY',
                password: '',
                confirm_password: '',
            };
        }
        this.setState({ formData, homePageView });
    }

    validateData(type) {
        const { formData, errorMessage } = this.state;
        let hasErrors = false;

        if (!/^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(formData.email)) {
            hasErrors = true;
            errorMessage.email = 'Enter a valid email';
        }
        if (type === 'register') {
            if (formData.phone_number && !/^\d{10}$/.test(formData.phone_number)) {
                hasErrors = true;
                errorMessage.phone_number = 'Enter a valid phone number';
            }
            if (formData.dob && new Date(formData.dob) > new Date()) {
                hasErrors = true;
                errorMessage.dob = 'Date must be less than today\'s';
            }
            if (formData.confirm_password && formData.password !== formData.confirm_password) {
                hasErrors = true;
                errorMessage.confirm_password = 'The password and confirm password must match.';
            }
        }

        Object.keys(formData).forEach((key) => {
            if (typeof (formData[key]) === 'string' && key !== 'password' && key !== 'confirm_password') {
                if (!formData[key].match(/\w/)) {
                    hasErrors = true;
                    errorMessage[key] = 'This field is mandatory';
                }
            }
            if (!formData[key]) {
                hasErrors = true;
                errorMessage[key] = 'This field is mandatory';
            }
        });
        if (hasErrors) {
            this.setState({ errorMessage });
            return false;
        }
        return formData;
    }

    handleLogin(e) {
        e.preventDefault();
        this.updateErrors({});
        const validData = this.validateData('login');
        if (validData) {
            this.setState({ isDisabled: true });
            this.props.postLoginUser(validData).then((resp) => {
                this.setState({ isDisabled: false });
                const response = resp && resp.data;
                const companyId = response.data.current_company_id;
                const officeId = response.data.current_office_id;

                if (response && !response.success) {
                    this.updateErrors(response.data);
                } else if (response) {
                    if (response) {
                        localStorage.setItem('accessToken', response.data.token);
                    }
                    if (this.props.widgetJobId || this.props.handleOnSuccess) {
                        this.props.handleOnSuccess();
                    } else {
                        const pathUrl = window.location.pathname;
                        let redirectUrl = '/s/job-dashboard';
                        if (pathUrl.indexOf('?redirectUrl=') !== -1) {
                            redirectUrl = pathUrl.replace('?redirectUrl=', '').replace('#/', '');
                        } else if (companyId && officeId) {
                            redirectUrl = {
                                pathname: `/e/${companyId}/${officeId}/dashboard`,
                                state: {
                                    companyId,
                                    officeId
                                }
                            };
                        }
                        this.props.history.push(redirectUrl);
                        window.location.reload();
                    }
                }
            });
        }
    }

    handleRegister(evt) {
        evt.preventDefault();
        this.updateErrors({});
        const validData = this.validateData('register');
        if (validData) {
            this.setState({ isDisabled: true });
            this.props.postRegisterUser(validData).then(resp => {
                this.setState({ isDisabled: false });
                const response = resp && resp.data;
                if (response && !response.success) {
                    this.updateErrors(response.data);
                } else if (response) {
                    if (!response.data.token) {
                        return;
                    } 
                    localStorage.setItem('accessToken', response.data.token);
                    this.props.postLoginUser(validData).then(() => {
                        if (this.props.widgetJobId || this.props.handleOnSuccess) {
                            this.props.handleOnSuccess();
                        } else {
                            this.props.history.push('/s/job-dashboard');
                        }
                    });
                }
            });
        }
    }

    handleForgotPassword(evt) {
        evt.preventDefault();
        this.updateErrors({});
        const validData = this.validateData('forgotPassword');
        if (validData) {
            this.setState({ isDisabled: true });
            this.props.postResetPassword(validData).then((resp) => {
                this.setState({ isDisabled: false });
                const response = resp && resp.data;
                if (response && response.success) {
                    this.props.setAppMessage('success', 'A reset password link has been mailed to your email!');
                    $('.close-button').click();
                } else if (response) {
                    this.updateErrors(response.data);
                }
            });
        }
    }

    registerEmployer(e) {
        e.preventDefault();
        this.updateErrors({});
        const validData = this.validateData('register');

        if (validData) {
            this.setState({ isDisabled: true });
            this.props.postCreateCompanyAndOffice(validData).then(resp => {
                this.setState({ isDisabled: false });
                const response = resp && resp.data;
                if (response && response.success) {
                    this.props.postLoginUser(validData).then(lResp => {
                        const loginResp = lResp && lResp.data;
                        if (!loginResp.data.token) {
                            return;
                        }
                        localStorage.setItem('accessToken', loginResp.data.token);
                        const companyId = loginResp.data.current_company_id;
                        const officeId = loginResp.data.current_office_id;
                        const pathUrl = window.location.pathname;
                        let redirectUrl = '/e/dashboard';
                        if (pathUrl.indexOf('?redirectUrl=') !== -1) {
                            redirectUrl = pathUrl.replace('?redirectUrl=', '').replace('#/', '');
                        } else if (companyId && officeId) {
                            redirectUrl = {
                                pathname: `/e/${companyId}/${officeId}/manage-company`,
                                state: {
                                    companyId,
                                    officeId,
                                }
                            };
                        }
                        this.props.history.push(redirectUrl);
                        window.location.reload();
                    });
                } else if (response) {
                    const { errorMessage } = this.state;
                    Object.keys(response.data).forEach(key => {
                        errorMessage[key] = response.data[key][0];
                    });
                    this.setState({ errorMessage });
                }
            });
        } else {
            console.log('Errors need to be fixed.');
        }
    }

    handleChangeSelection(option, label) {
        const { formData, errorMessage } = this.state;
        let value = null;
        if (option && Array.isArray(option)) {
            if (option.length) {
                value = [];
                option.map((loc) => {
                    value.push(loc.val);
                });
            }
        } else if (option) {
            value = option.val;
        }
        formData[label] = value;
        if (errorMessage[label]) {
            errorMessage[label] = null;
            this.updateErrors(errorMessage);
        }
        this.setState({
            formData,
            errorMessage,
        });
    }

    handleChange(evt) {
        const { name, value } = evt.target;
        const { formData, errorMessage } = this.state;

        if (errorMessage[name]) {
            errorMessage[name] = null;
            this.updateErrors(errorMessage);
        }

        formData[name] = value;
        this.setState({ formData });
    }

    handleDatePicker(evt, date, id) {
        const { formData, errorMessage } = this.state;

        if (errorMessage[id]) {
            errorMessage[id] = null;
            this.setState({ errorMessage });
        }

        formData[id] = date;
        this.setState({ formData });
    }

    handleDPOpenStateChange(status) {
        this.setState({
            dpOpenStatus: status,
        });
    }

    render() {
        const {
            errorMessage, isDisabled, homePageView, activeClass, dpOpenStatus, formData
        } = this.state;
        const {
            onModalClose, widgetJobId, handleOnSuccess, actionSelected, metaData,
        } = this.props;
        let loginRedirect = '';

        const sizeOptions = [
            { val: '', name: '-Select Size-' },
            { val: '0-100', name: '0-100' },
            { val: '100-500', name: '100-500' },
            { val: '500-1000', name: '500-1000' },
            { val: 'more than 1000', name: 'more than 1000' },
        ];

        const categorySelect = [{ val:'', name:'-Select Category-' }];

        if (metaData && metaData.category && metaData.category.length) {
            metaData.category.map(categoryData => {
                categorySelect.push({ val: categoryData.term, name: categoryData.term });
            });
        }

        if (widgetJobId || handleOnSuccess) {
            const url = window.location.href;
            if (url.indexOf('?') == -1) {
                loginRedirect = `${url}?actionSelected=${actionSelected}&jobId=${widgetJobId}`;
            } else {
                loginRedirect = `${url}&actionSelected=${actionSelected}&jobId=${widgetJobId}`;
            }
        }
        let leftModal = null;
        let modalHeader = '';
        const toggleRegister = (
            <div className="w3-center">
                <div className="h7t-toggle-register">
                    <span id="signup" className={activeClass === 'signup' ? 'active' : ''} onClick={() => this.handleViewChange('signup')}>Job Seeker</span>
                    <span id="employer" className={activeClass === 'employer' ? 'active' : ''} onClick={() => this.handleViewChange('employer')}>Employer</span>
                </div>
            </div>
        );

        if (homePageView === 'login') {
            modalHeader = 'Login';
            leftModal = (
                <div key="leftLoginModal">
                    <form className="form-signin form-horizontal" onSubmit={this.handleLogin}>
                        <FormTextField
                            placeholder="Email *"
                            name="email"
                            id="email"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.email}
                        />
                        <FormTextField
                            type="password"
                            placeholder="Password *"
                            name="password"
                            id="password"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.password}
                        />
                        <div className="w3-panel">
                            <button className="w3-btn h7t-btn-background-blue w3-block" type="submit" disabled={isDisabled}>Sign in</button>
                            <div className="w3-center w3-margin-top">
                                <a onClick={() => this.handleViewChange('forgot_password')} className="h7t-cursor-pointer h7t-hyperlink-color">Forgot password?</a>
                            </div>
                        </div>
                    </form>
                    <div key="rightmodal" className="w3-margin-bottom">
                        <div className="w3-center w3-margin"><span>OR</span></div>
                        <div className="w3-row w3-panel">
                            <div className="w3-col m6 w3-margin-bottom">
                                <a
                                    href={`${BaseAjaxDomain.host}/v1/api/auth/facebook-connect?redirectUrl=${loginRedirect}`}
                                    className="w3-button w3-block w3-indigo h7t-social-btn-align"
                                    onClick={() => localStorage.setItem('widgetRedirectUrl', JSON.stringify(loginRedirect))}
                                >
                                    <i className="ion-social-facebook h7t-social-icon" />
                                    &nbsp;facebook
                                </a>
                            </div>
                            <div className="w3-col m6 w3-margin-bottom">
                                <a
                                    // href={`${BaseAjaxDomain.host}/v1/api/auth/linkedin?redirectUrl=${loginRedirect}`} // ** disabled due to permission issue
                                    className="w3-button w3-block w3-blue-grey h7t-social-btn-align"
                                    onClick={() => {
                                        return;
                                        localStorage.setItem('widgetRedirectUrl', JSON.stringify(loginRedirect))
                                    }}
                                >
                                    <i className="ion-social-linkedin h7t-social-icon" />
                                    &nbsp;linkedIn
                                </a>
                            </div>
                            <div className="w3-col m6 w3-margin-bottom">
                                <a
                                    href={`${BaseAjaxDomain.host}/v1/api/auth/google-connect?redirectUrl=${loginRedirect}`}
                                    className="w3-button w3-block w3-red h7t-social-btn-align"
                                    onClick={() => localStorage.setItem('widgetRedirectUrl', JSON.stringify(loginRedirect))}
                                >
                                    <i className="ion-social-googleplus h7t-social-icon" />
                                    &nbsp;googleplus
                                </a>
                            </div>
                            <div className="w3-col m6 w3-margin-bottom">
                                <a
                                    href={`${BaseAjaxDomain.host}/v1/api/auth/twitter-connect?redirectUrl=${loginRedirect}`}
                                    onClick={() => localStorage.setItem('widgetRedirectUrl', JSON.stringify(loginRedirect))}
                                    className="w3-button w3-block w3-blue h7t-social-btn-align"
                                >
                                    <i className="ion-social-twitter h7t-social-icon" />
                                    &nbsp;twitter
                                </a>
                            </div>
                        </div>

                        <div className="w3-center">
                            You don't have an account? <a className="w3-margin-top h7t-hyperlink-color h7t-cursor-pointer" onClick={() => this.handleViewChange('signup')}>Register</a><br />
                        </div>
                    </div>
                </div>
            );
        } else if (homePageView === 'signup') {
            modalHeader = 'Register';
            leftModal = (
                <div key="leftmodal">
                    {toggleRegister}
                    <form className="form-horizontal" onSubmit={this.handleRegister}>
                        <FormTextField
                            type="text"
                            placeholder="First Name *"
                            name="first_name"
                            id="first_name"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.first_name}
                        />

                        <FormTextField
                            type="text"
                            placeholder="Last Name *"
                            name="last_name"
                            id="last_name"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.last_name}
                        />

                        <FormTextField
                            placeholder="Email *"
                            name="email"
                            id="email"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.email}
                        />

                        <FormTextField
                            type="text"
                            placeholder="Phone Number *"
                            name="phone_number"
                            type="text"
                            id="phone_number"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.phone_number}
                        />

                        <div className="w3-panel">
                            <label>Gender <span className="w3-text-red">*</span></label><br />
                            <label htmlFor="gender_male" className="h7t-cursor-pointer w3-margin-right">
                                <input
                                    type="radio"
                                    name="gender"
                                    id="gender_male"
                                    value="Male"
                                    className="h7t-cursor-pointer"
                                    onClick={this.handleChange}
                                />
                                &nbsp;Male
                            </label>
                            <label htmlFor="gender_female" className="h7t-cursor-pointer">
                                <input
                                    type="radio"
                                    name="gender"
                                    id="gender_female"
                                    value="Female"
                                    className="h7t-cursor-pointer"
                                    onClick={this.handleChange}
                                />
                                &nbsp;Female
                            </label>
                            <ErrorHelperText errors={errorMessage.gender} />
                        </div>

                        <div className="w3-padding">
                            <label>Date of Birth <span className="w3-text-red">*</span></label>
                            <DatePicker
                                className="widget-date-picker"
                                open={dpOpenStatus}
                                disabledDate={current => current > moment().subtract(18, 'years')}
                                size="large"
                                onChange={(e, date) => this.handleDatePicker(e, date, 'dob')}
                                onOpenChange={this.handleDPOpenStateChange}
                            />
                            <ErrorHelperText errors={errorMessage.dob} />
                        </div>

                        <FormTextField
                            type="password"
                            placeholder="Password *"
                            name="password"
                            id="password"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.password}
                        />

                        <FormTextField
                            type="password"
                            placeholder="Confirm Password *"
                            name="confirm_password"
                            id="confirm_password"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.confirm_password}
                        />
                        <div className="w3-panel">
                            <button className="w3-btn h7t-btn-background-blue w3-block" type="submit" disabled={isDisabled}>Create account</button>
                            <div className="w3-center w3-margin-top">
                                <a onClick={() => this.handleViewChange('login')} className="h7t-cursor-pointer h7t-hyperlink-color">Login</a>
                            </div>
                        </div>
                    </form>
                </div>
            );
        } else if (homePageView === 'forgot_password') {
            modalHeader = 'Forgot Password';
            leftModal = (
                <div key="leftmodal">
                    <form className="form-horizontal" onSubmit={this.handleForgotPassword}>
                        <p className="w3-margin">Enter your email address and we will send you a link to reset your password.</p>
                        <FormTextField
                            placeholder="Email address *"
                            name="email"
                            id="email"
                            inputClass="w3-input w3-border"
                            onChange={this.handleChange}
                            errors={errorMessage.email}
                        />

                        <div className="w3-panel">
                            <button className="w3-btn h7t-btn-background-blue w3-block" type="submit" disabled={isDisabled}>Send password reset email</button>
                            <div className="w3-center w3-margin-top">
                                <a onClick={() => this.handleViewChange('login')} className="h7t-cursor-pointer h7t-hyperlink-color">Login</a>
                                &nbsp;&nbsp;&nbsp;
                                <a onClick={() => this.handleViewChange('signup')} className="h7t-cursor-pointer h7t-hyperlink-color">Register</a>
                            </div>
                        </div>
                    </form>
                </div>
            );
        } else if (homePageView === 'employer') {
            modalHeader = 'Register';
            leftModal = (
                <div key="leftmodal">
                    {toggleRegister}
                    <form className="form-horizontal" onSubmit={this.registerEmployer}>
                        <FormTextField
                            onChange={this.handleChange}
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="Person Name *"
                            inputClass="w3-input w3-border"
                            errors={errorMessage.first_name}
                        />

                        <FormTextField
                            onChange={this.handleChange}
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Company Name *"
                            inputClass="w3-input w3-border"
                            errors={errorMessage.name}
                        />

                        <FormTextField
                            onChange={this.handleChange}
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Company Email *"
                            inputClass="w3-input w3-border"
                            errors={errorMessage.email}
                        />

                        <FormTextField
                            onChange={this.handleChange}
                            id="phone_number"
                            type="text"
                            name="phone_number"
                            placeholder="Phone Number *"
                            inputClass="w3-input w3-border"
                            errors={errorMessage.phone_number}
                            />

                        <InputSelectField
                            label="Company Size"
                            id="size"
                            name="size"
                            value={formData.size}
                            options={sizeOptions}
                            errors={errorMessage.size}
                            onChange={this.handleChange}
                            required
                        />

                        <SearchDropdown
                            label="Select Category"
                            options={categorySelect}
                            value={formData.category}
                            errors={errorMessage.category}
                            onChange={(evt, val) => {
                                this.handleChangeSelection(val, 'category')
                            }}
                        />

                        <FormTextField
                            onChange={this.handleChange}
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password *"
                            inputClass="w3-input w3-border"
                            errors={errorMessage.password}
                        />

                        <FormTextField
                            onChange={this.handleChange}
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="Confirm Password *"
                            inputClass="w3-input w3-border"
                            errors={errorMessage.confirm_password}
                        />

                        <div className="w3-panel">
                            <button
                                className="w3-btn h7t-btn-background-blue w3-block"
                                type="submit"
                                disabled={isDisabled}
                            >Create account</button>
                            <div className="w3-center w3-margin-top">
                                <a
                                    onClick={() => this.handleViewChange('login')}
                                    className="h7t-cursor-pointer h7t-hyperlink-color"
                                >Login</a>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }

        return (
            <Modal
                modalCls="w3-show"
                onClose={onModalClose}
                widthCls="chp-small-dlg"
                className="w3-animate-top h7t-login-signup-modal w3-margin-bottom"
                handleOpen={this.handleDPOpenStateChange}
                datePickerStatus={dpOpenStatus}
            >
                <div className="w3-text-black">
                    <div className="modal-header">
                        <h4 className="modal-title">{modalHeader}</h4>
                    </div>
                    <div className="w3-row">
                        {leftModal}
                    </div>
                </div>
            </Modal>

        );
    }
}

function mapStateToProps(state) {
    return {
        metaData: state.meta,
    };
}
export default withRouter(connect(mapStateToProps, {
    postCreateCompanyAndOffice: registerCompanyAndOffice,
    postResetPassword,
    postRegisterUser,
    postLoginUser,
    setAppMessage,
    getMetaData,
})(WidgetLoginSignupModal));
