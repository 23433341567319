import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import {
    postApplyJob,
    setAppMessage,
    getCurrentUser,
    getResumeViewList,
    postUserResumeViewCreate,
} from '../../js/sdkActions';
import {
    FormSelectField,
    FormTextField,
    ErrorHelperText,
} from '../Common/HelperComponents';
import Modal from '../Common/Modal';
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button
} from '@material-ui/core';

class ShowJobDetails extends Component {
    constructor(args) {
        super(args);
        this.state = {
            errors: {},
            form: {
                title: '',
                covering_letter_text: '',
                resume_file_path: '',
            },
            formSelectType: null,
        };
        this.handleChangeResumeView = this.handleChangeResumeView.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleCoverLetter = this.handleCoverLetter.bind(this);
    }

    componentDidMount() {
        let formSelectType = null;
        if (this.props.showApplyForm) {
            this.props.getResumeViewList().then((response) => {
                if (response && response.data && response.data.data.length) {
                    formSelectType = 'selectResume';
                } else {
                    formSelectType = 'uploadNew';
                }
                this.setState({ formSelectType });
            });
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.showApplyForm !== this.props.showApplyForm) {
            let formSelectType = null;
            this.props.getResumeViewList().then((response) => {
                if (response && response.data && response.data.data.length) {
                    formSelectType = 'selectResume';
                } else {
                    formSelectType = 'uploadNew';
                }
                this.setState({ formSelectType });
            });
        }
    }

    handleBookmarkJob(jobSelected) {
        if (this.props.handleBookmarkJob) {
            this.props.handleBookmarkJob(jobSelected);
        }
    }

    validateData() {
        const { form, errors } = this.state;
        let hasErrors = false;

        if (form.resume_file_path && form.resume_file_path.size > 2097152) {
            hasErrors = true;
            errors.resume_file_path = 'File size is larger than 2MB';
        }

        if (!form.resume_file_path) {
            hasErrors = true;
            errors.resume_block_ids = 'Upload your resume or select profile to submit the form';
        }

        Object.keys(form).forEach((key) => {
            if (typeof (form[key]) === 'string') {
                if (!form[key].match(/\w/)) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
            if (!form[key]) {
                hasErrors = true;
                errors[key] = 'This field is mandatory';
            }
        });

        if (hasErrors) {
            this.setState({ errors });
            return false;
        }
        return form;
    }

    handleApplyJob(job, evt) {
        evt.preventDefault();
        const { form } = this.state;
        const { jobSelected } = this.props;

        this.props.getCurrentUser().then((resp) => {
            const response = resp.data.data;
            if (response && response.user) {
                if (job === 'apply') {
                    this.props.postApplyJob(jobSelected.id, form.resume_view_id, jobSelected.company_id, jobSelected.office_id).then((resp) => {
                        if (resp && resp.data && resp.data.success) {
                            this.props.setAppMessage('success', 'Applied successfully.');
                            this.setState({
                                form: {
                                    resume_view_id: '',
                                },
                            });
                            jobSelected.applicationStatus = resp.data.data.status;
                        } else if (resp.data.data) {
                            this.setState({ errors: resp.data.data });
                        } else {
                            this.props.setAppMessage('fail', resp.data.message);
                        }
                    });
                } else if (job === 'create') {
                    const validData = this.validateData();
                    if(validData) {
                        form.display_order = 1;
                        form.resume_block_ids = [];
                        const sendFormData = new FormData();
                        if (sendFormData) {
                            sendFormData.append('title', form.title);
                            sendFormData.append('covering_letter_text', form.covering_letter_text);
                            sendFormData.append('display_order', form.display_order);
                            sendFormData.append('resume_block_ids', form.resume_block_ids);
                            sendFormData.append('resume_file_path', form.resume_file_path);

                            this.props.postUserResumeViewCreate(sendFormData).then((resp) => {
                                if (resp && resp.data && resp.data.success) {
                                    this.props.postApplyJob(jobSelected.id, resp.data.data.id, jobSelected.company_id, jobSelected.office_id).then((response) => {
                                        if (response && response.data && response.data.success) {
                                            this.props.setAppMessage('success', 'Applied successfully.');
                                            this.setState({
                                                form: {
                                                    title: '',
                                                    covering_letter_text: '',
                                                    resume_file_path: '',
                                                },
                                            });
                                            document.getElementById('resume_file_path').value = null;
                                            jobSelected.applicationStatus = response.data.data.status;
                                        }
                                    });
                                } else {
                                    this.setState({ errors: resp.data.data });
                                }
                            });
                        }
                    }
                } else if (this.props.handleApplyJob) {
                    this.props.handleApplyJob(jobSelected);
                }
            } else {
                this.props.handleApplyJob(jobSelected);
            }
        });
    }

    handleChangeResumeView(evt) {
        const { errors, form } = this.state;
        const targetElement = $(evt.target).attr('id');
        if (errors[targetElement]) {
            errors[targetElement] = null;
        }
        if (targetElement === 'resume_file_path') {
            form.resume_file_path = evt.target.files[0] ? evt.target.files[0] : '';
        } else {
            form[targetElement] = $(evt.target).val();
        }
        this.setState({ form, errors });
    }

    handleToggle(formSelectType) {
        this.setState({
            formSelectType,
            form: {
                title: '',
                covering_letter_text: '',
                resume_file_path: '',
            },
        });
    }

    handleCoverLetter(content) {
        const { errors, form } = this.state;
        if (errors.covering_letter_text) {
            errors.covering_letter_text = null;
        }

        form.covering_letter_text = (content == '<p><br></p>') ? '' : content;
        this.setState({ form, errors });
    }

    render() {
        const {
            jobSelected, bookmarkIds, showApplyForm, resumeViewList, onModalClose
        } = this.props;
        const { formSelectType, errors, form } = this.state;
        let applyButton = null;

        const minExp = jobSelected.min_experience_required;
        const maxExp = jobSelected.max_experience_required;
        const expText =`${minExp} - ${maxExp} Yrs`;
        const salary_min = jobSelected.salary_min;
        const salary_max = jobSelected.salary_max;
        const salText = `${salary_min} - ${salary_max} LPA`;
        
        const resumeOption = [
            { val: null, name: 'Select your resume' },
        ];
        if (resumeViewList && resumeViewList.data) {
            resumeViewList.data.map((resume) => {
                resumeOption.push({ val: resume.id, name: resume.title });
            });
        }

        if (showApplyForm) {
            const applyType = (formSelectType === 'selectResume') ? 'apply' : 'create';
            applyButton = (
                <Button
                    className={`w3-margin h7t-margin-10 h7t-secondary-btn ${jobSelected.applicationStatus ? 'w3-disabled' : ''} `}
                    onClick={(e) => this.handleApplyJob(applyType, e)}
                    disabled={jobSelected.applicationStatus}
                >
                    {jobSelected.applicationStatus ? 'Applied' : 'Apply'}
                </Button>
            );
        }
        return (
            <Modal
                modalCls="w3-show"
                onClose={onModalClose}
            >
                <div className="w3-row w3-padding w3-margin">
                    <div className="w3-row w3-padding">
                        <div className="w3-col s2 m2">
                            {(jobSelected && jobSelected.logoUrl) 
                                ? (
                                    <div className="h7t-profile-pic w3-center">
                                        <img style={{ width: '100%', objectFit: 'contain' }} alt="" src={(jobSelected && jobSelected.logoUrl) ? jobSelected.logoUrl : ''} />
                                    </div>
                                ) : null } 
                        </div>
                        <div className="w3-col s8 m8">
                            <span className="w3-text-deep-purple" style={{ fontSize: 'large' }}><strong>{jobSelected.job_title}</strong></span>
                            <div className="w3-row">
                                {jobSelected.companyName?<div className="w3-col m6">
                                    <span className="w3-text">{jobSelected.companyName}</span> 
                                    <br />
                                    <div className="w3-row">
                                        <div className="w3-col m4">
                                            {jobSelected && jobSelected.current_office && jobSelected.current_office.city_info && jobSelected.current_office.city_info.term ?
                                                <span className="w3-text">{jobSelected.current_office.city_info.term} <br /></span> : null}
                                        </div>
                                    </div>
                                </div>:''}
                            </div>
                        </div>
                    </div>
                    <div className="w3-row w3-padding w3-margin-top">
                        <span className="w3-text-deep-purple">Job Description</span>
                        <div className="w3-col m12 margin-top-10 h7t-ql-editor" dangerouslySetInnerHTML={{__html: jobSelected.description}} />
                    </div>
                    {jobSelected.project_description 
                        ? (
                        <div className="w3-row w3-padding w3-margin-top">
                            <span className="w3-text-deep-purple">Project Description</span>
                            <div className="w3-col m12 margin-top-10 h7t-ql-editor" dangerouslySetInnerHTML={{ __html: jobSelected.project_description }} />
                        </div>
                        ) : null }
                    <div className="w3-row w3-padding w3-margin-top">
                        <span className="w3-text-deep-purple">Skills</span> 
                        <div className="w3-text margin-top-10">{jobSelected.skills_required.split(',').join(', ')} </div> 
                    </div> 
                    {jobSelected.education
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Education</span> 
                                <div className="w3-text margin-top-10">{jobSelected.education} </div>  
                            </div>
                        ) : null }
                    {jobSelected.universities
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Universities</span> 
                                <div className="w3-text margin-top-10">{jobSelected.universities} </div>  
                            </div>
                        ) : null }
                    {jobSelected.domain 
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Domain</span> 
                                <div className="w3-text margin-top-10">
                                    {jobSelected.domain}
                                </div>
                            </div>
                        ) : null }
                    {jobSelected.certifications
                        ? (
                            <div className="w3-row w3-padding w3-margin-top">
                                <span className="w3-text-deep-purple">Certifications</span> 
                                <div className="w3-text margin-top-10">
                                    <ul>
                                        <li>{jobSelected.certifications}</li>
                                    </ul> 
                                </div>
                            </div>
                        ) : null }
                    <div className={`w3-row w3-padding ${jobSelected.certifications?'':'w3-margin-top'}`}>
                        <span className="w3-text-deep-purple">Details</span>
                        <br />
                        <div className="w3-col m12 margin-top-10">
                            Years of Experience : <span className="companyInfo">{expText}</span> <br />
                            Level of Hiring : <span className="w3-text">{jobSelected.level_of_hiring}</span> <br />
                            CTC : <span className="w3-text">{salText} </span> <br /> 
                            {jobSelected && jobSelected.current_office && jobSelected.current_office.name 
                            ? (
                                <div>
                                    Office : <span className="w3-text">{jobSelected.current_office.name}</span> <br />
                                </div>
                            ) : null } 
                            Positions Open : <span className="w3-text">{jobSelected.positions_open} </span>
                            <br />
                            Remote Job : <span className="w3-text">{(jobSelected.remote_Jobs === null || jobSelected.remote_Jobs === false)?'No':'Yes'}</span> <br />
                            Posted on : <span className="w3-text">{moment(jobSelected.created_at).format('MMM Do, YYYY')} </span> 
                        </div>
                    </div>    
                    
                    <div className="w3-bar w3-panel">
                        {(formSelectType !== 'uploadNew' && formSelectType !== 'selectResume')
                            ? (
                                <Button
                                    className={`w3-margin-right h7t-margin-10 h7t-secondary-btn ${jobSelected.applicationStatus ? 'w3-disabled' : ''} `}
                                    onClick={(e) => this.handleApplyJob(jobSelected, e)} 
                                    disabled={jobSelected.applicationStatus}
                                >
                                    {jobSelected.applicationStatus ? 'Applied' : 'Apply'}
                                </Button>
                            ) : null}
                             <Button
                            className={`w3-margin-right h7t-margin-10 h7t-secondary-btn ${bookmarkIds.includes(jobSelected.id) ? 'w3-disabled' :''}`}
                            onClick={() => this.handleBookmarkJob(jobSelected)}
                            disabled={bookmarkIds.includes(jobSelected.id) ? true : ''}
                        >
                            {bookmarkIds.includes(jobSelected.id) ? 'Saved' : 'Save'}
                        </Button>
                    </div>

                    {jobSelected.companyName ? <div className="w3-row w3-padding w3-margin-top">
                        <span className="w3-text-deep-purple">About {jobSelected.companyName}</span>
                        <br />
                        <div className="margin-top-10 w3-margin-bottom h7t-ql-editor" dangerouslySetInnerHTML={{__html: jobSelected.companyAbout }} />
                            <TableContainer component={Paper}>
                                <Table className="table-striped">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Website
                                            </TableCell>
                                            <TableCell>
                                                Team Size
                                            </TableCell>
                                            <TableCell>
                                                Type
                                            </TableCell>
                                            <TableCell>
                                                Industry
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><a target="_blank" href={jobSelected.companyWebsite}>{jobSelected.companyWebsite}</a></TableCell>
                                            <TableCell>{jobSelected.companySize}</TableCell>
                                            <TableCell>{jobSelected.companyType}</TableCell>
                                            <TableCell>{jobSelected.companyCategory}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </div>:''}

                    {showApplyForm
                        ? (
                            <form encType="multipart/form-data">
                                {formSelectType === 'selectResume'
                                    ? (
                                        <div className="w3-row">
                                            <div className="w3-row w3-margin">
                                                <div className="w3-col m3">
                                                    <label>Select a resume <span className="w3-text-red">*</span></label>
                                                </div>
                                                <div className="w3-col m9">
                                                    <FormSelectField
                                                        name="resume_view_id"
                                                        id="resume_view_id"
                                                        selectClass="w3-select h-select-big w3-border"
                                                        options={resumeOption}
                                                        errors={errors.resume_view_id}
                                                        value={form.resume_view_id}
                                                        onChange={e => this.handleChangeResumeView(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w3-row w3-center">
                                                OR<br />
                                                <button
                                                    type="button"
                                                    className="w3-button w3-border w3-margin-top w3-padding-small"
                                                    onClick={() => this.handleToggle('uploadNew')}
                                                >
                                                    Upload a new resume
                                                </button>
                                            </div>
                                            {applyButton}
                                        </div>
                                    ) : formSelectType === 'uploadNew'
                                        ? (
                                            <div className="w3-row">
                                                <div className="w3-col m12">
                                                    <FormTextField
                                                        label="Title"
                                                        name="title"
                                                        id="title"
                                                        isMandatory
                                                        inputClass="w3-input w3-border"
                                                        errors={errors.title}
                                                        value={form.title}
                                                        onChange={e => this.handleChangeResumeView(e)}
                                                    />

                                                    <div className="w3-padding">
                                                        <label>Covering Letter <span className="w3-text-red">*</span></label>
                                                        <ReactQuill
                                                            value={form.covering_letter_text ? form.covering_letter_text : ''}
                                                            placeholder="Covering Letter"
                                                            style={{ height: '100px', marginBottom: '50px' }}
                                                            onChange={e => this.handleCoverLetter(e)}
                                                        />
                                                        <ErrorHelperText errors={errors.covering_letter_text} />
                                                    </div>

                                                </div>

                                                <div className="w3-row w3-margin-top w3-padding">
                                                    <div className="w3-col m3">
                                                        <label>Upload Resume <span className="w3-text-red">*</span></label>
                                                    </div>
                                                    <div className="w3-col m7">
                                                        <input id="resume_file_path" name="resume_file_path" type="file" onChange={e => this.handleChangeResumeView(e)} />
                                                    </div>
                                                    <div className="w3-col m12">
                                                        <ErrorHelperText errors={errors.resume_file_path} />
                                                    </div>
                                                </div>
                                                {(resumeViewList && resumeViewList.data && resumeViewList.data.length) ? 
                                                    (
                                                        <div className="w3-row w3-center">
                                                            OR<br />
                                                            <button
                                                                type="button"
                                                                className="w3-button w3-border w3-margin-top w3-padding-small"
                                                                onClick={() => this.handleToggle('selectResume')}
                                                            >
                                                                Select resume from profile
                                                            </button>
                                                        </div>
                                                    ) : null}
                                                {applyButton}
                                            </div>
                                        ) : null}
                            </form>
                        ) : null}
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser && state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        currentUser,
        appMessage: state.appMessage,
        resumeViewList: state.resumeViewList,
    };
}
export default connect(mapStateToProps, {
    postApplyJob,
    setAppMessage,
    getCurrentUser,
    getResumeViewList,
    postUserResumeViewCreate,
})(ShowJobDetails);
