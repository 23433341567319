import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

export class FilterList extends Component {
    isEmptyFilter() {
        console.log(this.props.match.params, this.state);
        if (this.props.match.params.filter !== undefined) {
            return false;
        }

        if (this.state.searchQuery !== '') {
            return false;
        }
        return true;
    }
}

export class ErrorHelperText extends Component {
    static get defaultProps() {
        return {
            errors: null,
        };
    }

    render() {
        let errorContent = null;
        if (!this.props.errors) {
            return <span />;
        }
        if (typeof (this.props.errors) === 'string') {
            errorContent = (<div className="w3-text-red">* {this.props.errors}</div>);
        } else {
            errorContent = (<div className="w3-text-red">* {this.props.errors.join(',')}</div>);
        }
        return errorContent;
    }
}

export class FormTextField extends Component {
    static get defaultProps() {
        return {
            type: 'text',
            valueChange: null,
            inputClass: '',
            noLabel: false,
            isMandatory: false,
            example: '',
            max: '',
            onBlur() {

            },
            defaultValue: '',
            textClass: '',
        };
    }

    constructor(args) {
        super(args);

        this.state = {
            value: null,
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            value: newProps.value,
        });
    }

    render() {
        if (this.state.value == null) {
            this.state.value = this.props.value;
        }
        let errorClass = '';
        if (this.props.errors !== undefined) {
            errorClass = 'is-invalid-input';
        }

        let inputClass = '';
        if (this.props.inputClass !== undefined) {
            inputClass = this.props.inputClass;
        }

        let mandatoryField = '';
        if (this.props.isMandatory) {
            mandatoryField = (<span className="w3-text-red">*</span>);
        }

        return (
            <div className={'w3-panel'}>
                <label>{this.props.label} {mandatoryField} {this.props.example}</label>
                <input
                    type={this.props.type}
                    id={this.props.id}
                    name={this.props.name}
                    value={this.state.value}
                    className={this.props.textClass}
                    onBlur={this.props.onBlur}
                    onChange={this.props.onChange}
                    placeholder={this.props.placeholder}
                    max={this.props.max}
                    className={`${this.props.inputClass} ${errorClass}`}
                />

                <ErrorHelperText errors={this.props.errors} />

            </div>
        );
    }
}

export class NavigationSwitcher extends Component {
    componentDidUpdate() {
        if (this.props.selected) {
            $(this.refs.own_select).val(this.props.selected);
        }
    }

    handleChange(evt) {
        hashHistory.push(`${this.props.basePath}/${evt.target.value}`);
    }

    render() {
        const options = [];
        if (this.props.options) {
            options.push(<option key="all" value="">All</option>);
            if (this.props.options) {
                const list = this.props.options.forEach((item) => {
                    options.push(<option key={item} value={item}>{item}</option>);
                });
            }
        }

        if (this.props.map) {
            for (const key in this.props.map) {
                const val = this.props.map[key];
                options.push(<option key={key} value={key}>{val}</option>);
            }
        }
        return (<select ref="own_select" onChange={this.handleChange.bind(this)}>
            {options}
        </select>);
    }
}
export class FormTextarea extends Component {
    static get defaultProps() {
        return {
            valueChange: null,
            isMandatory: false,
        };
    }

    constructor(args) {
        super(args);

        this.state = {
            value: null,
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            value: newProps.value,
        });
    }

    render() {
        if (this.state.value == null) {
            this.state.value = this.props.value;
        }
        let errorClass = '';
        if (this.props.errors !== undefined) {
            errorClass = 'is-invalid-input';
        }

        let mandatoryField = '';
        if (this.props.isMandatory) {
            mandatoryField = (<span className="w3-text-red">*</span>);
        }

        return (
            <div className="w3-panel">
                <label>{this.props.label} {mandatoryField}</label>
                <textarea
                    id={this.props.id}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    className={`${this.props.inputClass} ${errorClass}`}
                    value={this.state.value}
                    style={{ minHeight: '80px' }}
                />
                <ErrorHelperText errors={this.props.errors} />
            </div>

        );
    }
}

export class FormSelectField extends Component {
    static get defaultProps() {
        return {
            valueChange: null,
            wrapClass: '',
            options: [],
            disabled: false,
            isMandatory: false,
        };
    }

    constructor(args) {
        super(args);

        this.state = {
            value: null,
            optionList: [],
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            value: newProps.value,
            optionList: newProps.options,
        });
    }

    render() {
        if (this.state.value == null) {
            this.state.value = this.props.value;
        }

        if (this.state.optionList.length === 0) {
            this.state.optionList = this.props.options;
        }

        let mandatoryField = '';
        if (this.props.isMandatory) {
            mandatoryField = (<span className="w3-text-red">*</span>);
        }

        let selOpts = null;
        let errorClass = '';
        if (this.props.errors !== undefined) {
            errorClass = 'is-invalid-input';
        }
        selOpts = this.state.optionList.map((item) => {
            if ((item.type === 'customOption') && (item.status !== 'active')) {
                return (
                    <option key={item.val} value={item.val} disabled={item.status === 'pending' ? true : false}>
                        {/* <Grid container spacing={3} className="w3-panel">
                            <Grid item xs={12} sm={6}>{item.name}</Grid>
                            <Grid item xs={12} sm={6} className="w3-right">{item.status}</Grid>
                        </Grid> */}
                        {`${item.name} -- Invite Pending`}
                    </option>
                )
            }           

            if (item.customOption) {
                return (
                    item.customOption
                );
            }
            if (item.val) {
                return (
                    <option key={item.val} value={item.val}>{item.name}</option>
                );
            }

            return (
                <option key={item.val} value="" disabled selected>{item.name}</option>
            );
        });

        return (
            <div className={this.props.wrapClass}>
                <label>{this.props.label} {mandatoryField}</label>
                <select
                    id={this.props.id}
                    name={this.props.name}
                    value={this.state.value}
                    defaultValue={this.props.defaultValue}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    className={`${this.props.selectClass} ${errorClass}`}
                >
                    {selOpts}
                    {this.props.children}
                </select>
                <ErrorHelperText errors={this.props.errors} />

            </div>
        );
    }
}

export class LoadingIcon extends Component {
    render() {
        return (
            <div className="w3-row m12 h7t-loader ">
                <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                </div>
            </div>
        );
    }
}

// disabled={this.props.disabled}
