import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import {
    setAppMessage,
    getDomainJobList,
    getMetaData,
    getCurrentUser,
    getDomainOfficeList,
    postAddBookmark,
    getDomainJobDetailsById,
} from '../../js/sdkActions';
import WidgetLoginSignupModal from './WidgetLoginSignupModal';
import StickyInfoBar from '../Common/StickyInfoBar';
import {
    LoadingIcon,
} from '../Common/HelperComponents';
import ShowJobDetails from './ShowJobDetails';
import { Button } from '@material-ui/core';

const { Option } = Select;

class JobsWidget extends Component {
    constructor(args) {
        super(args);
        this.state = {
            bookmarkIds: [],
            loginSignup: false,
            jobSelected: null,
            actionSelected: null,
            showApplyForm: false,
            showJobDetailsView: false,
            searchList: null,
            filterData: {},
        };
        this.handleDetails = this.handleDetails.bind(this);
        this.handleApplyJob = this.handleApplyJob.bind(this);
        this.handleBookmarkJob = this.handleBookmarkJob.bind(this);
    }

    componentDidMount() {
        const {
            domainJobList, domainOfficeList, metaData, domainId
        } = this.props;
        window.DOMAIN_ID = domainId;

        if (!domainJobList) {
            this.props.getDomainJobList();
        }
        if (!domainOfficeList) {
            this.props.getDomainOfficeList();
        }
        if (!metaData) {
            const skillsList = {};
            skillsList.type = 'skills';
            this.props.getMetaData(skillsList);
        }

        this.props.getCurrentUser().then((resp) => {
            const response = resp.data.data;
            if (response && response.profile && response.profile.bookmark_ids) {
                const bookmarkIds = response.profile.bookmark_ids.split(',')
                    .map((x) => {
                        return parseInt(x)
                    });
                this.setState({
                    bookmarkIds,
                });
            }
        });

        const queryUrl = window.location.href;
        const queryParams = {};
        if (queryUrl) {
            const checkParams = queryUrl.split('?');
            if (checkParams.length > 1) {
                const queryData = queryUrl.split('?')[1].split('&');
                queryData.forEach((item) => {
                    const qParam = item.split('=');
                    if (qParam.length == 2) {
                        queryParams[qParam[0]] = qParam[1];
                    }
                });
            }
        }

        if (queryParams && queryParams.actionSelected && queryParams.jobId) {
            this.props.getDomainJobDetailsById(queryParams.jobId).then((resp) => {
                const response = resp && resp.data;
                if (response && response.success) {
                    if (queryParams.actionSelected == 'bookmark') {
                        this.handleBookmarkJob(response.data[0]);
                    } else if (queryParams.actionSelected == 'applyjob') {
                        this.handleApplyJob(response.data[0]);
                    }
                }
            });
        }
    }

    handleDetails(jobSelected) {
        this.setState({
            showJobDetailsView: true,
            jobSelected,
        });
    }

    handleBookmarkJob(jobSelected) {
        if (!jobSelected) {
            return;
        }
        let bookmarkIds = [];
        this.props.getCurrentUser().then((resp) => {
            const loginUser = resp.data.data;
            if (loginUser && loginUser.user) {
                if (loginUser.profile && loginUser.profile.bookmark_ids) {
                    bookmarkIds = loginUser.profile.bookmark_ids.split(',')
                        .map((x) => {
                            return parseInt(x)
                        });
                }
                if (bookmarkIds.includes(jobSelected.id)) {
                    this.props.setAppMessage('fail', 'You have already saved this job...!');
                    this.props.getDomainJobList().then(() => {
                        this.setState({
                            loginSignup: '',
                            bookmarkIds,
                            actionSelected: null,
                        });
                    });
                    return;
                }
                bookmarkIds.push(jobSelected.id);
                const bookmark_ids = bookmarkIds.join();
                this.props.postAddBookmark(bookmark_ids).then((response) => {
                    if (response && response.data && response.data.success) {
                        this.props.setAppMessage('success', 'Job saved to your profile...!');
                        this.props.getDomainJobList().then(() => {
                            this.setState({
                                loginSignup: '',
                                bookmarkIds,
                                actionSelected: null,
                            });
                        });
                    }
                });
            } else {
                this.setState({
                    loginSignup: true,
                    jobSelected,
                    actionSelected: 'bookmark',
                });
            }
        });
    }

    handleApplyJob(jobSelected) {
        if (!jobSelected) {
            return;
        }
        this.props.getCurrentUser().then((resp) => {
            const loginUser = resp.data.data;
            if (loginUser && loginUser.user) {
                let bookmarkIds = [];
                let { showApplyForm } = this.state;
                if (loginUser.profile && loginUser.profile.bookmark_ids) {
                    bookmarkIds = loginUser.profile.bookmark_ids.split(',')
                        .map((x) => {
                            return parseInt(x)
                        });
                }
                this.props.getDomainJobList().then((res) => {
                    const result = res && res.data;
                    if (result && result.success) {
                        result.data.map((jobs) => {
                            if (jobSelected && jobs.id == jobSelected.id) {
                                jobSelected = jobs;
                                if (jobs.applicationStatus) {
                                    this.props.setAppMessage('fail', 'You have already applied for this job');
                                } else {
                                    showApplyForm = true;
                                }
                            }
                        });
                    }
                    this.setState({
                        loginSignup: '',
                        jobSelected,
                        bookmarkIds,
                        showApplyForm,
                    });
                });
            } else {
                this.setState({
                    loginSignup: true,
                    jobSelected,
                    actionSelected: 'applyjob'
                });
            }
        });
    }

    handleFilter(value, name) {
        const { filterData } = this.state;
        filterData[name] = value;
        filterData.searchKey = '';
        this.props.getDomainJobList(filterData).then(() => {
            this.setState({ filterData, searchList: null });
        });
    }

    handleSearchJob(event) {
        const { domainJobList } = this.props;
        const { filterData } = this.state;
        filterData.searchKey = event.target.value;
        let searchList = null;

        if (domainJobList && domainJobList.data && domainJobList.data.length > 0) {
            searchList = domainJobList.data.filter((item) => {
                return item.job_title.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
            });
        }
        this.setState({ searchList, filterData });
    }

    render() {
        const {
            domainJobList, appMessage, metaData, domainOfficeList,
        } = this.props;
        const {
            bookmarkIds,
            loginSignup,
            jobSelected,
            actionSelected,
            showApplyForm,
            showJobDetailsView,
            filterData,
            searchList,
        } = this.state;
        const companyJobs = [];

        const filterOnSkills = [<Option key="allSkill" value="All">All</Option>];
        if (metaData) {
            const { metaData: { skills } } = this.props;
            if (skills && skills.length) {
                skills.forEach((skill) => {
                    filterOnSkills.push(<Option key={skill.id} value={skill.term}>{skill.term}</Option>);
                });
            }
        }

        const selectOffice = [<Option key="allOffice" value="All">All</Option>];
        if (domainOfficeList && domainOfficeList.data) {
            domainOfficeList.data.map((office) => {
                selectOffice.push(<Option key={office.id} value={office.id}>{office.name}</Option>);
            });
        }

        const filterJobOpenings = (
            <div className="w3-row w3-center">
                <div className="w3-col m4 w3-padding">
                    <input
                        className="w3-input h-select-big w3-border w3-round w3-padding-small"
                        type="text"
                        name="search"
                        placeholder="Search"
                        value={filterData.searchKey ? filterData.searchKey : ''}
                        onChange={e => this.handleSearchJob(e)}
                    />
                </div>
                <div className="w3-col m4 h7t-left-right-padding">
                    <Select
                        style={{ borderRadius: '0px', border: 0 }}
                        size="large"
                        className="w3-select h-select-big"
                        placeholder="Select Office"
                        value={filterData.office_id}
                        onChange={e => this.handleFilter(e, 'office_id')}
                    >
                        {selectOffice}
                    </Select>
                </div>
                <div className="w3-col m4 h7t-left-right-padding">
                    <Select
                        showSearch
                        style={{ borderRadius: '0px', border: 0 }}
                        size="large"
                        className="w3-select h-select-big"
                        placeholder="Select skills"
                        value={filterData.skill}
                        onChange={e => this.handleFilter(e, 'skill')}
                        onSearch={this.handleSearch}
                    >
                        {filterOnSkills}
                    </Select>
                </div>
            </div>
        );

        if (!domainJobList || !domainJobList.data) {
            return <LoadingIcon />;
        }
        const jobListFiltered = searchList || domainJobList.data;
        if (jobListFiltered && jobListFiltered.length > 0) {
            jobListFiltered.map((job, key) => {
                const minExp = job.min_experience_required;
                const maxExp = job.max_experience_required;
                const expText = `${minExp} - ${maxExp} Yrs`;
                companyJobs.push(
                    <div key={key} className="w3-row w3-border w3-padding w3-margin">
                        <h5 className="w3-text-deep-purple">{job.job_title}</h5>
                        {expText}
                        <div className="w3-margin-top" dangerouslySetInnerHTML={{__html: job.description}} />
                        <p><span className="w3-text-deep-purple">Skills required : </span> {job.skills_required.split(',').join(', ')}</p>
                        <div className="w3-right h7t-float-none">
                            <Button
                                className={`h7t-secondary-btn w3-padding-small w3-margin-right h7t-mobile-view h7t-margin-10 ${bookmarkIds.includes(job.id) ? 'w3-disabled' : ''} `}
                                onClick={() => this.handleBookmarkJob(job)}
                                disabled={bookmarkIds.includes(job.id) ? true : ''}
                            >
                                {bookmarkIds.includes(job.id) ? 'Saved' : 'Save'}
                            </Button>
                            <Button
                                className="h7t-secondary-btn w3-padding-small w3-margin-right h7t-mobile-view h7t-margin-10"
                                onClick={() => this.handleDetails(job)}
                            >
                                Details
                            </Button>
                            <Button
                                className={`h7t-secondary-btn w3-padding-small w3-margin-right h7t-mobile-view h7t-margin-10 ${job.applicationStatus ? 'w3-disabled' : ''}`}
                                onClick={() => this.handleApplyJob(job)} 
                                disabled={job.applicationStatus}
                            >
                                {job.applicationStatus ? 'Applied' : 'Apply'}
                            </Button>
                        </div>
                    </div>,
                );
            });
        } else {
            companyJobs.push(
                <div key="no_jobs" className="w3-row w3-padding w3-margin w3-border w3-center">
                    <h5>No Openings Found!</h5>
                </div>,
            );
        }

        return (
            <div className="w3-container h7t-center-div">
                {(showApplyForm || showJobDetailsView)
                    ? (
                        <ShowJobDetails 
                            bookmarkIds={bookmarkIds}
                            showJobDetails={showJobDetailsView}
                            showApplyForm={showApplyForm}
                            handleBookmarkJob={this.handleBookmarkJob}
                            handleApplyJob={this.handleApplyJob}
                            jobSelected={jobSelected} 
                            onModalClose={() => this.setState({ jobSelected: null, showApplyForm: false, showJobDetailsView: false })}
                        />
                    ) : (
                        <div>
                            {filterJobOpenings}
                            {companyJobs}
                        </div>
                    )}

                {loginSignup
                    ? (
                        <WidgetLoginSignupModal 
                            homePageView="login"
                            widgetJobId={jobSelected.id}
                            handleOnSuccess={(actionSelected == 'bookmark') ? () => this.handleBookmarkJob(jobSelected) : () => this.handleApplyJob(jobSelected)}
                            onModalClose={() => this.setState({ loginSignup: false })}
                            actionSelected={actionSelected}
                        />
                    ) : null}
                {appMessage.messageText && <StickyInfoBar msgType={appMessage.messageType} msgText={appMessage.messageText} />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const currentUser = state.currentUser && state.currentUser.data ? state.currentUser.data : state.currentUser;
    return {
        domainJobList: state.domainJobList,
        currentUser,
        appMessage: state.appMessage,
        metaData: state.meta,
        domainOfficeList: state.domainOfficeList,
    };
}
export default connect(mapStateToProps, {
    setAppMessage,
    getCurrentUser,
    getDomainJobList,
    getMetaData,
    getDomainOfficeList,
    postAddBookmark,
    getDomainJobDetailsById,
})(JobsWidget);
